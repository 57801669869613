/**
 *
 * BiddingForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import { Form } from 'react-final-form';
import OptimizationAndBiddingStrategy from './OptimizationAndBiddingStrategy/OptimizationAndBiddingStrategy';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    formButton: {
        maxWidth: '42rem',
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '5rem',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '2rem',
    },
    pageContainer: {
        width: 'auto',
    },
    leftGrid: {
        height: '100%',
    },
    gridSection: {
        maxWidth: '42rem',
        marginTop: '1rem',
    },
    bidAndRadioGroup: {
        '&.MuiGrid-item': {
            marginTop: '1rem',
        },
    },
}));

export function BiddingForm({ loading, bidding }) {
    const classes = useStyles();

    const formData = bidding.get();

    return (
        <Grid container className={classes.pageContainer}>
            <Grid className={classes.leftGrid}>
                <Form
                    onSubmit={() => {
                        // TODO: Add submit() method for ad group settings form
                    }}
                    initialValues={formData}
                    validate={bidding.validateForm}
                    render={({ handleSubmit, submitting }) => (
                        <form onSubmit={handleSubmit} className={classes.content}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} className={classes.bidAndRadioGroup}>
                                    <OptimizationAndBiddingStrategy />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        data-testid="submit-bidding-form-button"
                                        className={classes.formButton}
                                        disabled={false}
                                        type="submit"
                                    >
                                        <span>{submitting || loading ? 'Loading...' : 'Continue'}</span>
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                />
            </Grid>
        </Grid>
    );
}

BiddingForm.propTypes = {
    loading: PropTypes.bool,
    bidding: PropTypes.object,
};

export default BiddingForm;
