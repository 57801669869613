/**
 *
 * AdGroupFrom
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Form } from 'react-final-form';
import CampaignManagementRadioGroupMapper from '../shared/CampaignManagementRadioGroupMapper';
import { AdGroupSettingsRadioGroupData } from '../shared/componentData/AdGroupSettingsRadioGroupData';
import { CampaignManagementNameField } from '../shared/CampaignManagementNameField';
import CampaignManagementDatePickerRange from '../shared/CampaignManagementDatePickerRange';
import AutoSave from '../../../components/AutoSave';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    formButton: {
        maxWidth: '42rem',
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '5rem',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '2rem',
    },
    pageContainer: {
        width: 'auto',
    },
    leftGrid: {
        height: '100%',
    },
    gridSection: {
        maxWidth: '42rem',
        marginTop: '1rem',
    },
    bidAndRadioGroup: {
        '&.MuiGrid-item': {
            marginTop: '-0.5rem',
        },
    },
}));

export function AdGroupForm({ loading, adGroup, next }) {
    const classes = useStyles();

    const formData = adGroup.get();

    const handleSave = (values) => {
        adGroup.save(values);
    };

    return (
        <Grid container className={classes.pageContainer}>
            <Grid className={classes.leftGrid}>
                <Form
                    onSubmit={() => adGroup.submit().then(next)}
                    initialValues={formData}
                    validate={adGroup.validateForm}
                    render={({ handleSubmit, submitting }) => (
                        <>
                            <AutoSave save={handleSave} />
                            <form onSubmit={handleSubmit} className={classes.content}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant="h2" component="h2" sx={{ fontWeight: 300 }}>
                                            Ad Group Settings
                                        </Typography>
                                        <br />
                                        <Grid item className={classes.gridSection}>
                                            <CampaignManagementNameField label="Ad Group Name" loading={loading} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CampaignManagementDatePickerRange dates={formData} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.bidAndRadioGroup}>
                                        <CampaignManagementRadioGroupMapper
                                            options={AdGroupSettingsRadioGroupData}
                                            boldRadioLabel
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            data-testid="submit-ad-group-setting-form-button"
                                            className={classes.formButton}
                                            disabled={false}
                                            type="submit"
                                        >
                                            <span>{submitting || loading ? 'Loading...' : 'Continue'}</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )}
                />
            </Grid>
        </Grid>
    );
}

AdGroupForm.propTypes = {
    loading: PropTypes.bool,
    adGroup: PropTypes.object,
    next: PropTypes.func,
};

export default AdGroupForm;
