import React from 'react';
import PropTypes from 'prop-types';
import OptimizationStrategyFormControlSelect from './OptimizationStrategyFormControlSelect';
import { SelectData } from '../../shared/componentData/SelectData';

export function OptimizationStrategy({ setOptStrategy }) {
    return (
        <OptimizationStrategyFormControlSelect
            label="Optimization Strategy"
            options={SelectData.optimizationStrategyData}
            setOptStrategy={setOptStrategy}
        />
    );
}

OptimizationStrategy.propTypes = {
    setOptStrategy: PropTypes.func,
};

export default OptimizationStrategy;
