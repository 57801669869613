export const roleLabels = {
    internal: {
        Internal_Developer: 'Developer',
        MyAds_System_Admin: 'System Admin',
        Internal_BBY_Admin: 'Sales/Account',
        Internal_Campaign_Admin: 'Campaign Admin',
        Internal_Campaign_Manager: 'Campaign Manager',
        Internal_Media_Planner: 'Media Planner',
        Internal_Report_View_User: 'Report Viewer',
    },
    external: {
        External_Admin: 'Admin',
        External_Report_View_User: 'View Reports',
        Demand_Partner_Api_User: 'Demand Partner Service User',
        External_Campaign_Manager: 'Campaign Manager',
        Billing_Admin: 'Billing Admin',
    },
};

export const RoleTypes = {
    Internal: 'internal',
    External: 'external',
};

export const allRolesLabels = { ...roleLabels.internal, ...roleLabels.external };
