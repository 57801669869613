import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, InputLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { HelpOutline } from '@material-ui/icons';
import OptimizationStrategy from './OptimizationStrategy';
import BaseBid from './BaseBid';
import MaxBid from './MaxBid';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 0 1rem 0',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 12,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 15,
            whiteSpace: 'nowrap',
        },
    },
    sectionContainer: {
        maxWidth: '42rem',
    },
    gridSection: {
        maxWidth: '22rem',
    },
    subtitle: {
        fontSize: '14px',
    },
    learnMore: {
        fontSize: '14px',
        color: theme.palette.techBlack,
        textDecoration: 'underline',
    },
    helpHover: {
        display: 'inline-block',
        color: theme.palette.humanBlue,
        marginBottom: '-6px',
        marginLeft: '8px',
        fontSize: '2rem',
    },
}));

const OptimizationAndBiddingStrategy = () => {
    const classes = useStyles();
    const [optStrategy, setOptStrategy] = useState('');

    return (
        <div className={classes.sectionContainer}>
            <div className={classes.header}>
                <Typography component="h2" align="left" className={classes.headline}>
                    Bidding Strategy
                    <HelpOutline className={classes.helpHover} />
                </Typography>
            </div>

            <Grid container direction="column" spacing={2}>
                <Grid item className={classes.gridSection}>
                    <InputLabel className={classes.inputLabel}>Optimization and Bidding Strategy</InputLabel>
                    <Typography variant="subtitle1" className={classes.subtitle}>
                        What do you want to optimize for?{' '}
                        <Link to="/learn-more" className={classes.learnMore}>
                            Learn More
                        </Link>
                    </Typography>
                </Grid>
                <Grid item className={classes.gridSection}>
                    <OptimizationStrategy setOptStrategy={setOptStrategy} />
                </Grid>
                <Grid item className={classes.gridSection}>
                    <BaseBid />
                    <MaxBid optStrategy={optStrategy} />
                </Grid>
            </Grid>
        </div>
    );
};

export default OptimizationAndBiddingStrategy;
