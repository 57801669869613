import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import { InputLabel, TextField } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    textValue: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '5px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
        marginBottom: '1rem',
    },
    subSubLabel: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '0px 0px 10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 300,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 12,
            whiteSpace: 'nowrap',
        },
        marginBottom: '1rem',
    },
    label: {
        width: '100%',
        maxWidth: '20rem',
    },
    optionsBelowRadial: {
        paddingTop: 0,
    },
    textArea: {
        width: '368px',
        marginBottom: '2rem',
    },
}));

export const TextAreaMapper = memo(({ textAreas }) => {
    const classes = useStyles();

    const keywordTextFields = textAreas.map((textArea) => (
        <Field name={textArea.name} component="input" type="text" key={`${textArea.name}-Field`}>
            {({ input }) => (
                <>
                    <InputLabel className={classes.textValue}>{textArea.subTitle}</InputLabel>
                    <InputLabel className={classes.subSubLabel}>Must have one keyword per line</InputLabel>
                    <TextField
                        {...input}
                        key={`${textArea.name}-textField`}
                        name={textArea.name}
                        className={classes.textArea}
                        inputProps={{ maxLength: 255, 'aria-label': 'keyword-text-area' }}
                        placeholder=""
                        variant="outlined"
                        multiline
                        minRows={8}
                        maxRows={25}
                    />
                </>
            )}
        </Field>
    ));

    return <div>{keywordTextFields}</div>;
});

TextAreaMapper.propTypes = {
    textAreas: PropTypes.array,
};
