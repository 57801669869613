/**
 *
 * CampaignSettingsForm
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-final-form';
import { Button, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import { formatForUSCurrency } from 'utils/formatForUSCurrency';
import { CampaignManagementRadioGroupMapper } from '../shared/CampaignManagementRadioGroupMapper';
import { CampaignSettingsRadioGroupData } from '../shared/componentData/CampaignSettingsRadioGroupData';
import { CampaignManagementNameField } from '../shared/CampaignManagementNameField';
import CampaignSettingsAttributions from './CampaignSettingsAttributions';
import CampaignManagementDatePickerRange from '../shared/CampaignManagementDatePickerRange';
import AutoSave from '../../../components/AutoSave';

const useStyles = makeStyles((theme) => ({
    root: {
        fontFamily: theme.typography.fontFamily,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        margin: '5px',
    },
    formButton: {
        maxWidth: '42rem',
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginBottom: '5rem',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    content: {
        backgroundColor: 'white',
        padding: '2rem',
    },
    pageContainer: {
        width: 'auto',
    },
    leftGrid: {
        height: '100%',
    },
    gridSection: {
        maxWidth: '42rem',
        paddingTop: '1rem',
    },
    nameFieldGrid: {
        paddingTop: '1rem',
    },
    balanceLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
    },
    noPaddingBottom: {
        '&.MuiGrid-item': {
            padding: '16px 16px 0 16px',
        },
    },
    noPaddingTop: {
        '&.MuiGrid-item': {
            padding: '0 16px 16px 16px',
        },
    },
}));

export function CampaignSettingsForm({ loading, campaignSettings, next }) {
    const classes = useStyles();

    const formData = campaignSettings.get();

    const formattedBalance = formatForUSCurrency(formData?.campaignBalance);

    const handleSave = (values) => {
        campaignSettings.save(values);
    };

    return (
        <Grid container className={classes.pageContainer}>
            <Grid className={classes.leftGrid}>
                <Form
                    className={classes.content}
                    onSubmit={() => campaignSettings.submit().then(next)}
                    initialValues={formData}
                    validate={campaignSettings.validateForm}
                    render={({ handleSubmit, submitting }) => (
                        <>
                            <AutoSave save={handleSave} />
                            <form onSubmit={handleSubmit} className={classes.content}>
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <Typography variant="h2" component="h2" sx={{ fontWeight: 300 }}>
                                            {formData?.campaignType} Campaign
                                        </Typography>
                                        <br />
                                        <Grid item xs={12}>
                                            <InputLabel className={classes.balanceLabel}>
                                                Account Available Balance
                                            </InputLabel>
                                            <Typography variant="h5" component="h5" sx={{ fontWeight: 300 }}>
                                                {formattedBalance}
                                            </Typography>
                                            <br />
                                        </Grid>
                                        <Grid item className={classes.gridSection}>
                                            <CampaignManagementNameField label="Campaign Name" loading={loading} />
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CampaignManagementDatePickerRange dates={formData} />
                                    </Grid>
                                    <Grid item xs={12} className={classes.noPaddingBottom}>
                                        <CampaignManagementRadioGroupMapper
                                            options={CampaignSettingsRadioGroupData}
                                            boldRadioLabel
                                        />
                                    </Grid>
                                    <Grid item xs={12} className={classes.noPaddingTop}>
                                        <CampaignSettingsAttributions />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            data-testid="submit-campaign-setting-form-button"
                                            className={classes.formButton}
                                            disabled={false}
                                            type="submit"
                                        >
                                            <span>{submitting || loading ? 'Loading...' : 'Continue'}</span>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </>
                    )}
                />
            </Grid>
        </Grid>
    );
}

CampaignSettingsForm.propTypes = {
    loading: PropTypes.bool,
    campaignSettings: PropTypes.object,
    next: PropTypes.func,
};

export default CampaignSettingsForm;
