import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, CardActions, CardContent, Grid, Card, Typography } from '@material-ui/core';
import CircleWithNumbersOutlineSvg from 'components/Svg/CircleWithNumbersOutline';
import CheckmarkOutlineSvg from 'components/Svg/CheckmarkOutline';
import { Add } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    button: {
        marginLeft: 'auto',
        backgroundColor: theme.palette.humanBlue,
        color: '#ffffff',
        fontFamily: theme.typography.fontFamily,
        borderRadius: '8px',
        height: 35,
        fontSize: 12,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textTransform: 'capitalize',
        '&:hover': {
            '& span': {
                color: theme.palette.darkBlue,
            },
            '& svg': {
                fill: theme.palette.darkBlue,
            },
        },
        '&:disabled': {
            cursor: 'default',
            opacity: 0.5,
        },
    },
    container: {
        boxShadow: 0,
    },
    gridContainer: {
        minHeight: '100px',
    },
    gridItem: {
        display: 'flex',
        justifyContent: 'center',
    },
}));

export const ButtonCard = ({ buttonText, id, title, subtitle, loading, onClick, circleNumber, completed }) => {
    const classes = useStyles();

    return (
        <>
            <Card className={classes.container} aria-label="metric card">
                <CardContent>
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={1}
                        className={classes.gridContainer}
                    >
                        <Grid item xs={3} className={classes.gridItem}>
                            {completed ? (
                                <CheckmarkOutlineSvg />
                            ) : (
                                <CircleWithNumbersOutlineSvg circleNumber={circleNumber} />
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography id={id} variant="h5">
                                {loading ? <Skeleton animation="wave" variant="text" width={140} height={19} /> : title}
                            </Typography>
                            <Typography variant="body2">
                                {loading ? (
                                    <Skeleton animation="wave" variant="text" width={75} height={50} />
                                ) : (
                                    subtitle
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={3} className={classes.gridItem}>
                            <CardActions>
                                {loading ? (
                                    <Skeleton animation="wave" variant="text" width={75} height={50} />
                                ) : (
                                    <Button
                                        className={classes.button}
                                        variant="contained"
                                        onClick={onClick}
                                        startIcon={<Add />}
                                    >
                                        {buttonText}
                                    </Button>
                                )}
                            </CardActions>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

ButtonCard.propTypes = {
    buttonText: PropTypes.string,
    id: PropTypes.string,
    loading: PropTypes.bool,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onClick: PropTypes.func,
    circleNumber: PropTypes.number,
    completed: PropTypes.bool,
};

ButtonCard.defaultProps = {
    buttonText: '',
    id: '',
    loading: true,
    subtitle: '',
    title: '',
};
