import { RadioNamesAndKeys } from './RadioGroupNamesAndKeys';

export const SelectData = {
    attributionSettingsData: {
        postClickSettingsOptions: [
            { name: 'Lookback Window 7 Days', value: '7' },
            { name: 'Lookback Window 14 Days', value: '14' },
            { name: 'Lookback Window 30 Days', value: '30' },
        ],
        postViewSettingsOptions: [
            { name: 'Lookback Window No Post-view', value: '0' },
            { name: 'Lookback Window 1 Day (Default)', value: '1' },
            { name: 'Lookback Window 7 Days', value: '7' },
            { name: 'Lookback Window 14 Days', value: '14' },
            { name: 'Lookback Window 30 Days', value: '30' },
        ],
        productMatchOptions: [
            { name: 'Same Product (default)', value: 'sameproduct' },
            { name: 'Same Product, Same Category', value: 'sameproduct,samecategory' },
            {
                name: 'Same Product, Same Category, Same Brand',
                value: 'sameproduct,samecategory,samebrand',
            },
        ],
    },
    optimizationStrategyData: [
        {
            name: 'Revenue',
            value: 'Revenue',
            radioGroupName: RadioNamesAndKeys.optStrategy.radioGroupName,
            radioKey: RadioNamesAndKeys.optStrategy.optStrategyRadioKey,
        },
        {
            name: 'Conversions',
            value: 'Conversions',
            radioGroupName: RadioNamesAndKeys.optStrategy.radioGroupName,
            radioKey: RadioNamesAndKeys.optStrategy.optStrategyRadioKey,
        },
        {
            name: 'Clicks',
            value: 'Clicks',
            radioGroupName: RadioNamesAndKeys.optStrategy.radioGroupName,
            radioKey: RadioNamesAndKeys.optStrategy.optStrategyRadioKey,
        },
    ],
};
