import React from 'react';
import PropTypes from 'prop-types';

const CheckmarkOutlineSvg = ({ width = 110, height = 110 }) => (
    <svg width={width} height={height} viewBox="0 0 110 110" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M54.75 109.5C24.5593 109.5 0 84.9407 0 54.75C0 24.5593 24.5593 0 54.75 0C84.9407 0 109.5 24.5593 109.5 54.75C109.5 84.9407 84.9407 109.5 54.75 109.5ZM54.75 7.82143C28.8871 7.82143 7.82143 28.8871 7.82143 54.75C7.82143 80.6129 28.8871 101.679 54.75 101.679C80.6129 101.679 101.679 80.6129 101.679 54.75C101.679 28.8871 80.6129 7.82143 54.75 7.82143Z"
            fill="#318000"
        />
        <path
            d="M48.2202 75.6066C47.1774 75.6066 46.1867 75.1895 45.4567 74.4595L29.8138 58.8166C28.3017 57.3045 28.3017 54.8016 29.8138 53.2895C31.3259 51.7774 33.8288 51.7774 35.3409 53.2895L47.9595 65.9081L73.8745 35.2481C75.2824 33.5795 77.7331 33.4231 79.4017 34.7788C81.0702 36.1866 81.2788 38.6374 79.8709 40.3059L51.1924 74.1988C50.4624 75.0331 49.4717 75.5545 48.3767 75.5545C48.3245 75.6066 48.2724 75.6066 48.2202 75.6066Z"
            fill="#318000"
        />
    </svg>
);

CheckmarkOutlineSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default CheckmarkOutlineSvg;
