import React from 'react';
import PropTypes from 'prop-types';
import FlyoutModal, { FlyoutContent } from 'components/FlyoutModal';
import { Field, Form } from 'react-final-form';
import { Button, makeStyles } from '@material-ui/core';
import LabelledCurrencyField from './LabelledCurrencyField';

const useStyles = makeStyles((theme) => ({
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
}));

const BidOverrideFlyout = ({ baseBid, bidModifier, isOpen, onClose, onSubmit, sku }) => {
    const classes = useStyles();

    const baseBidText = new Intl.NumberFormat('en-US', {
        trailingZeroDisplay: 'stripIfInteger',
        minimumFractionDigits: 2,
    }).format(baseBid);

    const bidModifierText = new Intl.NumberFormat('en-US', {
        trailingZeroDisplay: 'stripIfInteger',
        minimumFractionDigits: 2,
    }).format(bidModifier || 0);

    return (
        <FlyoutModal title="Set Bidding" open={isOpen} onClose={onClose}>
            {sku && (
                <FlyoutContent label="SKU">
                    <div>{sku}</div>
                </FlyoutContent>
            )}
            <FlyoutContent label="Base Bid">
                <div>{`$${baseBidText}`}</div>
            </FlyoutContent>
            <Form
                initialValues={{ bidModifier: bidModifierText }}
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Field
                            className={classes.inputStyle}
                            label="Set Bid Modifier"
                            name="bidModifier"
                            component={LabelledCurrencyField}
                            type="text"
                        />
                        <Button className={classes.formButton} type="submit">
                            Update Bidding
                        </Button>
                    </form>
                )}
            />
        </FlyoutModal>
    );
};
BidOverrideFlyout.propTypes = {
    sku: PropTypes.string,
    bidModifier: PropTypes.number,
    onSubmit: PropTypes.func.isRequired,
    baseBid: PropTypes.number,
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default BidOverrideFlyout;
