import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { TextAreaMapper } from './TextAreaMapper';

const useStyles = makeStyles(() => ({
    addKeywordButton: {
        marginTop: '16px',
        width: '368px',
        textTransform: 'capitalize',
    },
}));

const AddKeywordsFlyout = ({ buttonLabel, textAreas, onSubmit, defaultValues, setOpenFlyout, inputNames }) => {
    const classes = useStyles();

    const inputHasValue = (inputs, values) => {
        let hasValue = false;
        inputs.forEach((input) => {
            if (values[input]) {
                hasValue = true;
            }
        });
        return hasValue;
    };

    return (
        <Form
            initialValues={defaultValues}
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                    <TextAreaMapper textAreas={textAreas} />
                    <Button
                        data-testid={`submit-${buttonLabel}-form-button`}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!inputHasValue(inputNames, values)}
                        onClick={() => setOpenFlyout(false)}
                        className={classes.addKeywordButton}
                    >
                        Add Keywords
                    </Button>
                </form>
            )}
        />
    );
};

AddKeywordsFlyout.propTypes = {
    textAreas: PropTypes.array,
    buttonLabel: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    defaultValues: PropTypes.object,
    setOpenFlyout: PropTypes.func,
    inputNames: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddKeywordsFlyout;
