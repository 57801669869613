/**
 *
 * BillingInfoFlyout - A flyout modal for creating billing information.
 *
 */

import React, { useEffect, useState } from 'react';
import FlyoutModal from 'components/FlyoutModal';
import Input from 'components/Input';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { Button, Grid, InputLabel } from '@material-ui/core';
import { VALIDATE_ON_MODIFIED } from 'utils/constants';
import { makeStyles } from '@material-ui/core/styles';
import {
    composeValidators,
    isNotWhitespace,
    isValidEmail,
    maxLength,
    minLengthNoWhitespace,
    required,
} from 'utils/validators';
import { fetchBillingAddress } from 'api/billing-service';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        height: 14,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
    inputSubLabel: {
        height: 12,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 400,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
    content: {
        backgroundColor: 'white',
    },
    formButton: {
        height: 42,
        borderRadius: 4,
        padding: 0,
        marginTop: '24px',
        display: 'flex',
        width: '100%',
        backgroundColor: theme.palette.humanBlue,
        border: 'none !important',
        color: theme.palette.standardWhite,
        cursor: 'pointer',
        fontSize: '14px',
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: theme.palette.darkBlue,
        },
        '&:disabled': {
            backgroundColor: theme.palette.battleGray,
            color: theme.palette.techBlack,
            border: `2px solid ${theme.palette.borderGray}`,
        },
    },
    formFieldRow: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 12,
    },
    formFieldSubtitle: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
    },
    financialContactLabel: {
        height: 14,
        paddingTop: theme.spacing(1),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
    financialContactSubLabel: {
        height: 14,
        paddingTop: theme.spacing(1),
        color: theme.palette.techBlack,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 200,
        fontStyle: 'normal',
        fontStretch: 'normal',
        letterSpacing: 'normal',
        fontSize: 14,
    },
}));

// **** CONVENIENCE CONSTANTS ****
const componentInput = 'input';
const componentTypeText = 'text';
const componentOutlined = 'outlined';
const componentmarginDense = 'dense';

// **** COPY ****
const poOptionLabel = 'P.O.# (Optional)';
const poOptionPlaceholder = 'Form field for P.O. Number (Optional)';
const billingInfoSubtitle = 'Verify business information to be used for montly invoices and receiving email.';
const companyNameLabel = 'Company Name';
const companyNamePlaceholder = 'e.g. GeekBuy Inc';
const firstNameLabel = 'First Name';
const lastNameLabel = 'Last Name';
const primaryEmailLabel = 'Contact Email';
const primaryEmailPlaceholder = 'Enter Email Address';
const address1Label = 'Address 1';
const address1Placeholder = 'e.g. 123 Main St.';
const address2Label = 'Address 2';
const address2Placeholder = 'e.g. Suite 1';
const cityLabel = 'City';
const cityPlaceholder = 'e.g. Richfield';
const stateLabel = 'State';
const statePlaceholder = 'e.g. Minnesota';
const financialContactEmailLabel = 'Billing Email (Optional)';
const financialContactEmailSubLabel = 'Used for Financial Email Notifications';
const zipCodeLabel = 'Zip Code';
const zipCodePlaceholder = 'e.g. 12345';

// **** DATA TEST IDS ****
const poNumber = 'po-number';
const companyName = 'company-name';
const firstName = 'first-name';
const lastName = 'last-name';
const primaryEmail = 'primary-email';
const address1 = 'address-1';
const address2 = 'address-2';
const city = 'city';
const state = 'state';
const zipCode = 'zip-code';
const financialContactEmail = 'financial-contact-email';

const submitButtonLabel = 'Confirm Billing Information';

const inputIdProp = (name) => `add-billing-info-form-${name}`;
const inputDataTestIdProp = (name) => `add-billing-info-${name}-input`;

export default function BillingInfoFlyout({ title, open, onClose, setBillingConfirmed }) {
    const classes = useStyles();

    const [billingData, setBillingData] = useState({});
    useEffect(() => {
        fetchBillingAddress().then((data) => {
            setBillingData(data);
        });
    }, []);

    // TODO: Replace with handleSubmit function that actually submits the form to BillingInfo API
    const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
    const onSubmit = async () => {
        setBillingConfirmed(true);
        onClose();
        await sleep(300);
    };

    // const stateSelector = ({ input, ...rest }) => <Select {...input} {...rest} isDisabled />;

    return (
        <FlyoutModal title={title} open={open} onClose={onClose}>
            <Form
                className={classes.content}
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container alignItems="flex-start">
                            <Grid item xs={12}>
                                <InputLabel className={classes.formFieldSubtitle}>{billingInfoSubtitle}</InputLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Field
                                    name="po_number"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.purchaseOrder}
                                    validate={composeValidators(minLengthNoWhitespace(2), maxLength(50))}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={poOptionLabel}
                                            placeholder={poOptionPlaceholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': poNumber,
                                                'data-testid': inputDataTestIdProp(poNumber),
                                                id: inputIdProp(poNumber),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="company_name"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.companyName}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(50),
                                        minLengthNoWhitespace(2),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={companyNameLabel}
                                            placeholder={companyNamePlaceholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': companyName,
                                                'data-testid': inputDataTestIdProp(companyName),
                                                id: inputIdProp(companyName),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="first_name"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.firstName}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(50),
                                        minLengthNoWhitespace(2),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={firstNameLabel}
                                            placeholder={firstNameLabel}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': firstName,
                                                'data-testid': inputDataTestIdProp(firstName),
                                                id: inputIdProp(firstName),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="last_name"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.lastName}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(50),
                                        minLengthNoWhitespace(2),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={lastNameLabel}
                                            placeholder={lastNameLabel}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': lastName,
                                                'data-testid': inputDataTestIdProp(lastName),
                                                id: inputIdProp(lastName),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="primary_email"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.contactEmail}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        isValidEmail,
                                        maxLength(50),
                                        minLengthNoWhitespace(2),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={primaryEmailLabel}
                                            placeholder={primaryEmailPlaceholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': primaryEmail,
                                                'data-testid': inputDataTestIdProp(primaryEmail),
                                                id: inputIdProp(primaryEmail),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="address1"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.addressLine1}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(50),
                                        minLengthNoWhitespace(2),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={address1Label}
                                            placeholder={address1Placeholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': address1,
                                                'data-testid': inputDataTestIdProp(address1),
                                                id: inputIdProp(address1),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="address2"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.addressLine2}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(50),
                                        minLengthNoWhitespace(2),
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={address2Label}
                                            placeholder={address2Placeholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': address2,
                                                'data-testid': inputDataTestIdProp(address2),
                                                id: inputIdProp(address2),
                                                maxLength: 255,
                                            }}
                                            props={{}}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name={city}
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.city}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(50),
                                        minLengthNoWhitespace(2),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={cityLabel}
                                            placeholder={cityPlaceholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': city,
                                                'data-testid': inputDataTestIdProp(city),
                                                id: inputIdProp(city),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name={state}
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.state}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(12),
                                        minLengthNoWhitespace(5),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={stateLabel}
                                            placeholder={statePlaceholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': state,
                                                'data-testid': inputDataTestIdProp(state),
                                                id: inputIdProp(state),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <Field
                                    name="zip_code"
                                    component={componentInput}
                                    type={componentTypeText}
                                    defaultValue={billingData?.zipcode}
                                    validate={composeValidators(
                                        isNotWhitespace,
                                        maxLength(12),
                                        minLengthNoWhitespace(5),
                                        required,
                                    )}
                                    disabled
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputLabel={zipCodeLabel}
                                            placeholder={zipCodePlaceholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            noEdit
                                            inputProps={{
                                                'aria-label': zipCode,
                                                'data-testid': inputDataTestIdProp(zipCode),
                                                id: inputIdProp(zipCode),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        />
                                    )}
                                </Field>
                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel className={classes.financialContactLabel}>
                                    {financialContactEmailLabel}
                                </InputLabel>
                                <Field
                                    name="financial_contact_email"
                                    component={componentInput}
                                    type={componentTypeText}
                                    validate={composeValidators(isValidEmail, minLengthNoWhitespace(2), maxLength(50))}
                                >
                                    {(props) => (
                                        <Input
                                            fullWidth
                                            inputSublabel={financialContactEmailSubLabel}
                                            placeholder={primaryEmailPlaceholder}
                                            margin={componentmarginDense}
                                            validateOn={VALIDATE_ON_MODIFIED}
                                            variant={componentOutlined}
                                            inputProps={{
                                                'aria-label': financialContactEmail,
                                                'data-testid': inputDataTestIdProp(financialContactEmail),
                                                id: inputIdProp(financialContactEmail),
                                                maxLength: 255,
                                            }}
                                            {...props}
                                        ></Input>
                                    )}
                                </Field>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Button
                                data-testid="submit-billing-form-button"
                                className={classes.formButton}
                                type="submit"
                            >
                                {submitButtonLabel}
                            </Button>
                        </Grid>
                    </form>
                )}
            />
        </FlyoutModal>
    );
}

BillingInfoFlyout.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    setBillingConfirmed: PropTypes.func.isRequired,
};
