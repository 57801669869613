import React from 'react';
import PropTypes from 'prop-types';
import { Fab, Grid, makeStyles, Typography } from '@material-ui/core';
import AddButton from 'components/AddButton';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    topFlexContainer: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(4),
        border: `solid 1px ${theme.palette.battleGray}`,
        position: 'relative',
        backgroundColor: 'white',
        padding: '3rem',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    boldFont: {
        fontWeight: 'bold',
    },
    buttonWithPadding: {
        backgroundColor: '#0146BE',
        color: '#ffffff',
        boxShadow: 'none',
        fontSize: '14px',
        textTransform: 'capitalize',
        margin: '0 2rem 0 6rem',
        '& .MuiFab-label': {
            padding: '0 3.5rem',
        },
    },
}));

const EmptyState = ({ content, handleOpen, buttonLabel, keywords }) => {
    const classes = useStyles();
    const history = useHistory();

    return (
        <div className={classes.topFlexContainer}>
            <Grid container direction="column" spacing={2}>
                <Grid item xs={8}>
                    {keywords ? (
                        <Typography variant="h2">
                            <span>{content.heading}</span>
                            <span className={classes.boldFont}>{' (Optional)'}</span>
                        </Typography>
                    ) : (
                        <Typography variant="h2">{content.heading}</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {keywords ? (
                        <Typography variant="subtitle1">
                            <span className={classes.boldFont}>{'Recommendation: '}</span>
                            <span>{content.subtitle}</span>
                        </Typography>
                    ) : (
                        <Typography variant="subtitle1">{content.subtitle}</Typography>
                    )}
                </Grid>
            </Grid>
            <div>
                {keywords ? (
                    <Fab
                        className={classes.buttonWithPadding}
                        variant="extended"
                        color="primary"
                        size="large"
                        onClick={() => {
                            history.push('/create/sponsored/review');
                        }}
                    >
                        Skip
                    </Fab>
                ) : (
                    <AddButton label={buttonLabel} onClick={handleOpen} />
                )}
            </div>
        </div>
    );
};

EmptyState.propTypes = {
    content: PropTypes.shape({
        heading: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired,
    }).isRequired,
    handleOpen: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string,
    keywords: PropTypes.bool,
};

export default EmptyState;
