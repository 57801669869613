import React from 'react';
import PropTypes from 'prop-types';
import { FormSpy } from 'react-final-form';

export default function AutoSave({ save }) {
    return (
        <FormSpy
            onChange={({ values, dirty }) => {
                if (dirty) {
                    save(values);
                }
            }}
            subscription={{ values: true, dirty: true }}
        />
    );
}

AutoSave.propTypes = {
    save: PropTypes.func.isRequired,
};
