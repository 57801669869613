import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import Input from '../../../components/Input';
import { VALIDATE_ON_MODIFIED } from '../../../utils/constants';

export function CampaignManagementNameField({ pageNameField, label, loading }) {
    return (
        <Field name="name" component="input" type="text">
            {({ input, meta }) => (
                <Input
                    inputLabel={label}
                    fullWidth
                    margin="dense"
                    variant="outlined"
                    disabled={loading}
                    inputProps={{
                        'data-testid': `${pageNameField}-input`,
                        maxLength: 255,
                        style: { fontWeight: 600, fontSize: '14px', height: '1.5rem' },
                    }}
                    validateOn={VALIDATE_ON_MODIFIED}
                    value={input.value || pageNameField}
                    input={input}
                    meta={meta}
                />
            )}
        </Field>
    );
}

CampaignManagementNameField.propTypes = {
    pageNameField: PropTypes.string,
    label: PropTypes.string,
    loading: PropTypes.bool,
};

export default CampaignManagementNameField;
