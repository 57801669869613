import { defaultFormState } from './FormData';

export const campaignDefaults = {
    campaignSettings: {
        ...defaultFormState,
        name: 'FY25 Holiday Test Campaign',
        startDate: '2022-04-17',
        endDate: '2022-04-17',
        campaignBalance: 20000,
        monthlyBudgetOption: 'none',
        monthlyBudgetAmount: '0',
        lifetimeBudgetOption: 'uncapped',
        lifetimeBudgetAmount: '0',
        dailyBudgetOption: 'automated',
        dailyBudgetAmount: '0',
        postClickAttributionWindow: '7',
        postClickAttributionScope: 'sameproduct',
        postViewAttributionWindow: '1',
        postViewAttributionScope: 'sameproduct',
    },
    adGroup: {
        ...defaultFormState,
        name: 'FY25 Holiday Beats Studio Head Phones',
        startDate: '2025-01-04',
        endDate: '2025-02-01',
        monthlyBudgetOption: 'none',
        monthlyBudgetAmount: '0',
        lifetimeBudgetOption: 'uncapped',
        lifetimeBudgetAmount: '0',
        dailyBudgetOption: 'automated',
        dailyBudgetAmount: '0',
    },
    keywords: [],
    negativeKeywords: [],
    products: [
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description:
                'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
            sku: '12345',
            bsin: '12345',
            color: 'Black',
            upc: '123456789012',
            class: 'Electronics',
            category: 'Headphones',
            status: 'In Stock',
            bid: 1.25,
            baseBid: 1.25,
            price: 100,
        },
    ],
    searchProducts: [
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Matte Black',
            sku: '6498068',
            bsin: '6498068',
            color: 'Black',
            upc: '885569449553',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - White',
            sku: '6498069',
            bsin: '6498069',
            color: 'White',
            upc: '885569449554',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Red',
            sku: '6498070',
            bsin: '6498070',
            color: 'Red',
            upc: '885569449555',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Blue',
            sku: '6498071',
            bsin: '6498071',
            color: 'Blue',
            upc: '885569449556',
            price: 169.99,
            status: 'In Stock',
        },
        {
            image: {
                src:
                    'https://pisces.bbystatic.com/image2/BestBuy_US/images/products/6415/6415428_sd.jpg;maxHeight=100;maxWidth=100',
                description: 'Headphones',
            },
            description: 'Beats by Dr. Dre - Beats Studio Wireless Noise Cancelling Headphones - Green',
            sku: '6498072',
            bsin: '6498072',
            color: 'Green',
            upc: '885569449557',
            price: 169.99,
            status: 'In Stock',
        },
    ],
    bidding: {
        baseBid: 1.75,
        maxBidRadioGroup: '',
        optStrategy: '',
    },
    review: {
        campaignType: 'Sponsored Products',
        campaignName: 'FY25 Holiday Test Campaign name',
        adGroupName: 'FY25 Holiday Ad group name',
        campaignBalance: 20000,
        startDate: '2022-04-17',
        endDate: '2023-05-18',
        campaignBudgets: {
            adGroupBudgetCampaignBudget: [
                {
                    label: 'Campaign Capped Budget',
                    budgetAmount: 5000,
                },
                {
                    label: 'Remaining Budget',
                    budgetAmount: 15000,
                },
                {
                    label: 'Available Budget',
                    budgetAmount: 20000,
                },
            ],
            adGroupBudget: [
                {
                    label: 'Ad Group Budget',
                    budgetAmount: 2500,
                },
                {
                    label: 'Monthly Budget',
                    budgetAmount: 500,
                },
                {
                    label: 'Daily Budget',
                    budgetAmount: 150,
                },
            ],
        },
        OptAndBid: {
            optStrategy: 'Clicks',
            cpcBid: [2.5, 5.5],
            productsSelected: 5,
            keywords: 8,
        },
        attributions: {
            postClickSettings: {
                label: 'postClick',
                value: 14,
                scope: 'Same Product, Same Category, Same Brand',
            },
            productMatchPostClickRow: {
                label: 'postClick',
                value: 14,
                scope: 'Same Product, Same Category, Same Brand',
            },
            postViewSettings: {
                label: 'postView',
                value: 14,
                scope: 'Same Product',
            },
            postViewProductMatch: {
                label: 'postView',
                value: 14,
                scope: 'Same View, Same Product',
            },
        },
    },
};
