import React from 'react';
import LightweightTable from 'components/LightweightTable';
import { textColumn } from 'components/LightweightTable/columns';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    headerName: {
        textAlign: 'center',
    },
    cellName: {
        textAlign: 'center',
    },
    alignColumn: {
        width: '33%',
    },
}));

const Keywords = ({ data, onRemoveRow }) => {
    const classes = useStyles();

    const columns = [
        textColumn('keywords', 'Keywords', {
            headerClassName: `${classes.headerName} ${classes.alignColumn}`,
            cellClassName: `${classes.cellName} ${classes.alignColumn}`,
        }),
        textColumn('matchType', 'Match Type', {
            headerClassName: `${classes.headerName} ${classes.alignColumn}`,
            cellClassName: `${classes.cellName} ${classes.alignColumn}`,
        }),
    ];

    return (
        <LightweightTable
            data={data.map((row) => ({ ...row, key: row.keywords }))}
            columns={columns}
            showRemoveIcon
            onRemoveRow={onRemoveRow}
        />
    );
};

Keywords.propTypes = {
    data: PropTypes.array.isRequired,
    onRemoveRow: PropTypes.func.isRequired,
};

export default Keywords;
