import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import CampaignSettingsAttributionFormControlSelect from './CampaignSettingsAttributionFormControlSelect';
import { SelectData } from '../shared/componentData/SelectData';

const useStyles = makeStyles(() => ({
    sectionContainer: {
        maxWidth: '42rem',
        paddingBottom: '.5rem',
    },
    selectContainer: {
        paddingLeft: 0,
    },
    box: {
        display: 'flex',
        direction: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
}));

export function CampaignSettingsAttributions() {
    const classes = useStyles();
    return (
        <div className={classes.sectionContainer}>
            <Typography
                variant="h5"
                component="h5"
                sx={{
                    fontWeight: 300,
                }}
            >
                Attribution Settings
            </Typography>
            <Typography
                variant="subtitle1"
                sx={{
                    fontWeight: 300,
                }}
            >
                These Defaults are Best Buy recommended settings.
            </Typography>
            <br />
            <div className={classes.selectContainer}>
                <div className={classes.box}>
                    <CampaignSettingsAttributionFormControlSelect
                        label="Post-Click Settings"
                        id="post_click_settings"
                        name="postClickAttributionWindow"
                        options={SelectData.attributionSettingsData.postClickSettingsOptions}
                        isLeft
                    />
                    <CampaignSettingsAttributionFormControlSelect
                        label="Product Match"
                        id="post_click_settings_product"
                        name="postClickAttributionScope"
                        options={SelectData.attributionSettingsData.productMatchOptions}
                        isLeft={false}
                    />
                </div>
                <div className={classes.box}>
                    <br />
                    <CampaignSettingsAttributionFormControlSelect
                        label="Post-View Settings"
                        id="post_view_settings"
                        name="postViewAttributionWindow"
                        options={SelectData.attributionSettingsData.postViewSettingsOptions}
                        isLeft
                    />
                    <CampaignSettingsAttributionFormControlSelect
                        label="Product Match"
                        id="post_view_settings_product"
                        name="postViewAttributionScope"
                        options={SelectData.attributionSettingsData.productMatchOptions}
                        isLeft={false}
                    />
                </div>
            </div>
        </div>
    );
}

CampaignSettingsAttributions.propTypes = {};

export default CampaignSettingsAttributions;
