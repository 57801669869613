import React from 'react';
import { Grid, InputLabel, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MaxBidOptions } from '../../shared/componentData/BiddingRadioGroupData';
import CampaignManagementRadioGroupMapper from '../../shared/CampaignManagementRadioGroupMapper';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 15,
            whiteSpace: 'nowrap',
        },
    },
    gridSection: {
        maxWidth: '21rem',
    },
    subtitle: {
        marginTop: '6px',
    },
}));

const MaxBid = ({ optStrategy }) => {
    const classes = useStyles();

    return optStrategy === 'Conversions' || optStrategy === 'Revenue' ? (
        <Grid item className={classes.gridSection}>
            <InputLabel className={classes.inputLabel}>
                Max Bid
                <Typography variant="body2" className={classes.subtitle}>
                    Apply a hard cap to prevent your bid from exceeding this amount.
                </Typography>
            </InputLabel>
            <CampaignManagementRadioGroupMapper options={MaxBidOptions} boldRadioLabel={false} />
        </Grid>
    ) : null;
};

MaxBid.propTypes = {
    optStrategy: PropTypes.string,
};

export default MaxBid;
