import moment from 'moment';
import request, {
    formatGetOptions as getOptions,
    formatPutOptions as putOptions,
    formatPostOptions as postOptions,
} from '../../utils/request';
import { campaignListSchema } from '../api-service/maps';
import { BASE_URL_V1 } from '../api-service/Url_Constants';
import { responseMapping } from '../api-helpers/responseMapping';

export const getCampaignDetails = async (accountId) => {
    const options = getOptions();
    const { payload } = await request(`${BASE_URL_V1}/campaignMgmt/accounts/${accountId}/campaigns`, options);
    return responseMapping(campaignListSchema, payload);
};

export const saveCampaignDetails = async (accountId, data) => {
    const payload = {
        name: data.name,
        startIsoDateTime: moment.utc(data.startDate).toISOString(),
        endIsoDateTime: moment.utc(data.endDate).toISOString(),
        budgets: [
            {
                budgetType: 'DAILY',
                isAutoPacing: data.dailyBudgetOption === 'automated',
                budget: data.dailyBudgetOption === 'set' ? data.dailyBudgetAmount.replaceAll(',', '') : null,
            },
            {
                budgetType: 'MONTHLY',
                isAutoPacing: false,
                budget: data.monthlyBudgetOption === 'set' ? data.monthlyBudgetAmount.replaceAll(',', '') : null,
            },
            {
                budgetType: 'LIFETIME',
                isAutoPacing: false,
                budget: data.lifetimeBudgetOption === 'capped' ? data.lifetimeBudgetAmount.replaceAll(',', '') : null,
            },
        ],
        attributions: [
            {
                attributionType: 'postClick',
                attributionWindow: data.postClickAttributionWindow,
                attributionScope: data.postClickAttributionScope,
            },
            {
                attributionType: 'postView',
                attributionWindow: data.postViewAttributionWindow,
                attributionScope: data.postViewAttributionScope,
            },
        ],
    };

    if (data.campaignId) {
        const options = putOptions(payload);
        return request(`${BASE_URL_V1}/campaignMgmt/accounts/${accountId}/campaigns/${data.campaignId}`, options);
    }

    const options = postOptions(payload);
    return request(`${BASE_URL_V1}/campaignMgmt/accounts/${accountId}/campaigns`, options);
};
