import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { SidebarTab, SidebarTabView } from 'components/TabSidebar';
import { useParams, useHistory } from 'react-router-dom';
import CreateProducts from './Products/CreateProducts';
import ReviewForm from './Review/ReviewPage';
import AdGroupForm from './AdGroup/AdGroupForm';
import { CampaignSettingsForm } from './CampaignSettings/CampaignSettingsForm';
import { useSponsoredCreation } from './shared/useSponsoredCreation';
import KeywordsContainer from './Keywords/KeywordsContainer';
import BiddingForm from './Bidding/BiddingForm';

export const StepParam = {
    CAMPAIGN_SETTINGS: 'campaign-settings',
    AD_GROUP: 'ad-group',
    PRODUCTS: 'products',
    BIDDING: 'bidding',
    KEYWORDS: 'keywords',
    REVIEW: 'review',
};

const stepList = Object.values(StepParam);

const getTabValue = (tabParam) => {
    const tab = Object.values(StepParam).find((val) => val === tabParam?.toLowerCase());

    return tab || StepParam.CAMPAIGN_SETTINGS;
};

const useStyles = makeStyles(() => ({
    root: {
        padding: '3rem 0 3rem 0',
    },
    content: {
        width: '100%',
        minHeight: '600px',
        position: 'relative',
        top: '-3rem',
        backgroundColor: 'white',
    },
}));

function CreateSponsoredCampaign() {
    const history = useHistory();
    const { tab: tabParam } = useParams();

    const classes = useStyles();

    const tabValue = getTabValue(tabParam);

    const [currentStep, setCurrentStep] = useState(StepParam.CAMPAIGN_SETTINGS);

    const isPastStep = (step) => stepList.indexOf(step) < stepList.indexOf(currentStep);

    const getStatus = (step, isValid) => {
        if (!isPastStep(step)) {
            return 'NUMBER';
        }
        return isValid ? 'COMPLETE' : 'ERROR';
    };

    const next = () => {
        const currentIndex = stepList.indexOf(tabValue);
        const nextIndex = currentIndex + 1;
        const nextTab = stepList[nextIndex];
        if (!isPastStep(nextTab)) {
            setCurrentStep(nextTab);
        }
        history.push(`/create/sponsored/${nextTab}`);
    };

    const { campaignSettings, adGroup, products, bidding, keywords, negativeKeywords, review } = useSponsoredCreation();
    const { load: loadCampaignSettings } = campaignSettings;
    const { load: loadAdGroup } = adGroup;

    useEffect(() => {
        loadCampaignSettings();
        loadAdGroup();
    }, [loadCampaignSettings, loadAdGroup]);

    return (
        <div className={classes.root}>
            <SidebarTabView startingValue={tabValue}>
                <SidebarTab
                    type="submit"
                    label="Campaign Settings"
                    value={StepParam.CAMPAIGN_SETTINGS}
                    itemStatus={getStatus(StepParam.CAMPAIGN_SETTINGS, campaignSettings.isValid())}
                    itemNumber={stepList.indexOf(StepParam.CAMPAIGN_SETTINGS) + 1}
                    pathname={`/create/sponsored/${StepParam.CAMPAIGN_SETTINGS}`}
                >
                    <div className={classes.content}>
                        <CampaignSettingsForm campaignSettings={campaignSettings} next={next} />
                    </div>
                </SidebarTab>
                <SidebarTab
                    type="submit"
                    label="Ad Group"
                    value={StepParam.AD_GROUP}
                    itemStatus={getStatus(StepParam.AD_GROUP, adGroup.isValid())}
                    itemNumber={stepList.indexOf(StepParam.AD_GROUP) + 1}
                    disabled={!isPastStep(StepParam.CAMPAIGN_SETTINGS)}
                    pathname={`/create/sponsored/${StepParam.AD_GROUP}`}
                >
                    <div className={classes.content}>
                        <AdGroupForm adGroup={adGroup} next={next} />
                    </div>
                </SidebarTab>
                <SidebarTab
                    label="Products"
                    value={StepParam.PRODUCTS}
                    itemStatus={getStatus(StepParam.PRODUCTS, products.isValid())}
                    itemNumber={stepList.indexOf(StepParam.PRODUCTS) + 1}
                    disabled={!isPastStep(StepParam.AD_GROUP)}
                    pathname={`/create/sponsored/${StepParam.PRODUCTS}`}
                >
                    <div className={classes.content}>
                        <CreateProducts products={products} />
                    </div>
                </SidebarTab>
                <SidebarTab
                    label="Bidding"
                    value={StepParam.BIDDING}
                    itemStatus={getStatus(StepParam.BIDDING, bidding.isValid())}
                    itemNumber={stepList.indexOf(StepParam.BIDDING) + 1}
                    disabled={!isPastStep(StepParam.PRODUCTS)}
                    pathname={`/create/sponsored/${StepParam.BIDDING}`}
                >
                    <div className={classes.content}>
                        <BiddingForm bidding={bidding} />
                    </div>
                </SidebarTab>
                <SidebarTab
                    label="Keywords"
                    value={StepParam.KEYWORDS}
                    itemStatus={getStatus(StepParam.KEYWORDS, keywords.isValid())}
                    itemNumber={stepList.indexOf(StepParam.KEYWORDS) + 1}
                    disabled={!isPastStep(StepParam.BIDDING)}
                    pathname={`/create/sponsored/${StepParam.KEYWORDS}`}
                >
                    <div className={classes.content}>
                        <KeywordsContainer tabs={{ keywords, negativeKeywords }} />
                    </div>
                </SidebarTab>
                <SidebarTab
                    label="Review"
                    value={StepParam.REVIEW}
                    itemStatus="NUMBER"
                    itemNumber={stepList.indexOf(StepParam.REVIEW) + 1}
                    disabled={!isPastStep(StepParam.KEYWORDS)}
                    pathname={`/create/sponsored/${StepParam.REVIEW}`}
                >
                    <div className={classes.content}>
                        <ReviewForm review={review} campaignSettings={campaignSettings} adGroup={adGroup} />
                    </div>
                </SidebarTab>
            </SidebarTabView>
        </div>
    );
}

export default CreateSponsoredCampaign;
