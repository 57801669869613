import React, { useState } from 'react';
import BidOverrideFlyout from '../shared/BidOverrideFlyout';

const CampaignManagementSandboxPage = () => {
    const [flyoutOpen, setFlyoutOpen] = useState(false);
    return (
        <>
            <button type="button" onClick={() => setFlyoutOpen(true)}>
                Open Flyout
            </button>
            <BidOverrideFlyout
                baseBid={1.75}
                bidModifier={1.75}
                isOpen={flyoutOpen}
                onClose={() => setFlyoutOpen(false)}
                onSubmit={() => setFlyoutOpen(false)}
            />
        </>
    );
};

export default CampaignManagementSandboxPage;
