export const TextFieldData = {
    budget: {
        csTextFieldKey: 'csBudgetTextFieldKey',
        agTextFieldKey: 'agBudgetTextFieldKey',
        textFieldName: 'lifetimeBudgetAmount',
    },
    monthly: {
        csBudgetTextFieldKey: 'csMonthlyBudgetTextFieldKey',
        agBudgetTextFieldKey: 'agMonthlyBudgetTextFieldKey',
        textFieldName: 'monthlyBudgetAmount',
    },
    daily: {
        csTextFieldKey: 'csDailyBudgetTextFieldKey',
        agTextFieldKey: 'agDailyBudgetTextFieldKey',
        textFieldName: 'dailyBudgetAmount',
    },
    optBidding: {
        maxBidTextFieldKey: 'maxBidTextFieldKey',
        textFieldName: 'maxBidTextField',
    },
};
