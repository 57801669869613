import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Labels from 'components/Input/Labels';
import { makeStyles } from '@material-ui/core';
import { NumberTextField } from './CampaignManagementNumberTextField';

const useStyles = makeStyles((theme) => ({
    inputStyle: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: 14,
        fontStyle: 'normal',
        fontStretch: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        background: '#FFFFFF',
        borderRadius: '4px',
        marginBottom: '1em',
    },
}));

const LabelledCurrencyField = memo((props) => {
    const classes = useStyles();

    const {
        input: { name, value, onChange, id },
        key,
        label,
        disabled,
        ...otherProps
    } = props;

    return (
        <>
            <Labels inputLabel={label} htmlFor={id} />
            <NumberTextField
                className={classes.inputStyle}
                fullWidth
                margin="dense"
                name={name}
                value={value}
                key={key}
                onChange={onChange}
                disabled={disabled}
                {...otherProps}
            />
        </>
    );
});

LabelledCurrencyField.propTypes = {
    input: PropTypes.object,
    key: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
};

export default LabelledCurrencyField;
