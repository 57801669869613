import React, { useState } from 'react';
import { Divider, makeStyles, Tab, Typography } from '@material-ui/core';
import MaterialTabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import Keywords from './tables/Keywords';
import NegativeKeywords from './tables/NegativeKeywords';
import AddButton from '../../../components/AddButton';
import { KeywordData, KeywordsEmptyState, KeywordTabData } from '../shared/componentData/KeywordData';
import AddKeywordsFlyout from './shared/AddKeywordsFlyout';
import FlyoutModal from '../../../components/FlyoutModal';
import EmptyState from '../shared/EmptyState';

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem 0 2rem 0',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    tab: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: '12px',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'center',
        opacity: 1,
        color: theme.palette.lightGray,
        margin: '0 10px',
        '&:hover': {
            boxShadow: `inset 0 -4px 0 0 ${theme.palette.humanBlue}`,
        },
        '&.Mui-selected': {
            height: '30px',
            color: theme.palette.humanBlue,
        },
    },
    keywordTab: {
        width: '20px',
    },
    negativeKeywordTab: {
        width: '150px',
    },
    indicator: {
        backgroundColor: theme.palette.humanBlue,
        height: '4px',
    },
}));

const KeywordContainer = ({ tabs }) => {
    const classes = useStyles();
    const [currentTab, setCurrentTab] = useState(0);
    const [openFlyout, setOpenFlyout] = useState(false);

    const keywordsList = tabs.keywords.get();
    const negativeKeywordsList = tabs.negativeKeywords.get();

    const handleTabChange = (event, newValue) => {
        setCurrentTab(newValue);
    };

    const keywordFlyoutDefaultValues = {
        keywords: '',
        negativeKeywordsBroadMatch: '',
        negativeKeywordsExactMatch: '',
    };

    const handleAddKeyword = (values) => {
        if (!currentTab) {
            tabs.keywords.add(values);
        } else {
            tabs.negativeKeywords.add(values);
        }
    };

    const onCloseFlyoutModal = () => {
        setOpenFlyout(false);
    };

    const handleRemoveKeyword = (item) => {
        if (!currentTab) {
            tabs.keywords.remove(item.keywords);
        } else {
            tabs.negativeKeywords.remove(item.key);
        }
    };

    let title = '';
    let tableContent;
    let buttonLabel = '';
    let textAreas = [];
    let flyoutTitle = '';
    let listCount = 0;
    let inputNames = [];

    switch (currentTab) {
        case 0:
            title = `Keywords (${keywordsList.length})`;
            tableContent = <Keywords data={keywordsList} onRemoveRow={handleRemoveKeyword} />;
            buttonLabel = 'Keywords';
            textAreas = KeywordData.keywords;
            flyoutTitle = 'Add Keywords';
            listCount = keywordsList.length;
            inputNames = ['keywords'];
            break;
        case 1:
            title = `Negative Keywords (${negativeKeywordsList.length})`;
            tableContent = <NegativeKeywords data={negativeKeywordsList} onRemoveRow={handleRemoveKeyword} />;
            buttonLabel = 'Neg. Keywords';
            flyoutTitle = 'Negative Keywords';
            textAreas = KeywordData.negativeKeywords;
            listCount = negativeKeywordsList.length;
            inputNames = ['negativeKeywordsBroadMatch', 'negativeKeywordsExactMatch'];
            break;
        default:
            break;
    }

    const isNotEmpty = (words) => words.length > 0;
    const renderEmptyState = (tab, keywords, negativeKeywords) => {
        if (currentTab === 0 && !isNotEmpty(keywords)) {
            return (
                <EmptyState content={KeywordsEmptyState.keywords} handleOpen={() => {}} buttonLabel="Skip" keywords />
            );
        }
        if (currentTab === 1 && !isNotEmpty(negativeKeywords)) {
            return (
                <EmptyState
                    content={KeywordsEmptyState.negativeKeywords}
                    handleOpen={() => {}}
                    buttonLabel="Skip"
                    keywords
                />
            );
        }
        return null;
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography component="h2" align="left" className={classes.headline}>
                    {title}
                </Typography>
                <AddButton
                    label={buttonLabel}
                    onClick={() => {
                        setOpenFlyout(true);
                    }}
                    disabled={listCount > 1000}
                />
            </div>
            <MaterialTabs value={currentTab} onChange={handleTabChange} classes={{ indicator: classes.indicator }}>
                <Tab
                    label={KeywordTabData.KEYWORDS.label}
                    key={KeywordTabData.KEYWORDS.key}
                    className={`${classes.tab} ${classes.keywordTab}`}
                />
                <Tab
                    label={KeywordTabData.NEGATIVE_KEYWORDS.label}
                    key={KeywordTabData.NEGATIVE_KEYWORDS.key}
                    className={`${classes.tab} ${classes.negativeKeywordTab}`}
                />
            </MaterialTabs>
            <Divider />
            {tableContent}
            {renderEmptyState(currentTab, keywordsList, negativeKeywordsList)}
            <div>
                <FlyoutModal title={flyoutTitle} open={openFlyout} onClose={onCloseFlyoutModal}>
                    <AddKeywordsFlyout
                        buttonLabel={buttonLabel}
                        textAreas={textAreas}
                        onSubmit={handleAddKeyword}
                        defaultValues={keywordFlyoutDefaultValues}
                        setOpenFlyout={setOpenFlyout}
                        inputNames={inputNames}
                    />
                </FlyoutModal>
            </div>
        </div>
    );
};

KeywordContainer.propTypes = {
    tabs: PropTypes.shape({
        keywords: PropTypes.object.isRequired,
        negativeKeywords: PropTypes.object.isRequired,
    }).isRequired,
};

export default KeywordContainer;
