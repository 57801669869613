import { GET_SESSION_ID_REQUEST, GET_SESSION_ID_SUCCESS, GET_SESSION_ID_FAILURE } from './constants';

const initialState = {
    sessionId: null,
    loading: false,
    error: null,
};
export default function billingReducer(state = initialState, action) {
    switch (action.type) {
        case GET_SESSION_ID_REQUEST:
            return { ...state, loading: true, error: null };
        case GET_SESSION_ID_SUCCESS:
            return { ...state, loading: false, sessionId: action.payload };
        case GET_SESSION_ID_FAILURE:
            return { ...state, loading: false, error: action.error };
        default:
            return state;
    }
}
