import { call, put, takeLatest, select, takeLeading } from 'redux-saga/effects';
import { compact, concat, difference, uniq } from 'lodash';
import {
    ADD_CAMPAIGNS_TO_GROUP,
    CREATE_CAMPAIGN_GROUP,
    DELETE_CAMPAIGN_GROUP,
    GET_CAMPAIGN_GROUPS,
    GET_SHARED_CAMPAIGN_GROUPS,
    LOAD_CAMPAIGNS,
    REMOVE_CAMPAIGNS_FROM_GROUP,
    SHARE_CAMPAIGN_GROUP,
    UPDATE_CAMPAIGN_GROUP,
} from 'containers/App/constants';
import {
    campaignsLoaded,
    campaignsLoadingError,
    getCampaignGroupsSuccess,
    getCampaignGroupsError,
    createCampaignGroupSuccess,
    createCampaignGroupError,
    deleteCampaignGroupSuccess,
    deleteCampaignGroupError,
    addCampaignsToGroupSuccess,
    addCampaignsToGroupError,
    removeCampaignsFromGroupSuccess,
    shareCampaignGroupError,
    shareCampaignGroupSuccess,
    getSharedCampaignGroupsSuccess,
    getSharedCampaignGroupsError,
    updateCampaignGroupSuccess,
    updateCampaignGroupError,
} from 'containers/App/actions';

import { getAllCampaigns } from 'api/api-client';
import {
    createCampaignGroupApi,
    deleteCampaignGroupApi,
    updateCampaignGroupApi,
    getCampaignGroupListByUserApi,
    getSharedCampaignGroupListApi,
    addFilter,
    updateFilter,
    fetchSavedFilters,
    FilterExistsError,
} from 'api/user-management';
import { makeSelectCampaignGroups } from 'containers/App/selectors';
import {
    getSummaryDashboardFiltersSuccess,
    updateSummaryDashboardFilterSuccess,
    addSummaryDashboardFilterSuccess,
} from 'containers/SummaryDashboardPage/actions';
import {
    getFiltersError,
    getFiltersSuccess,
    addFilterSuccess,
    addFilterError,
    updateFilterSuccess,
    updateFilterError,
} from './actions';

import { ADD_FILTER, GET_FILTERS, UPDATE_FILTER } from './constants';

// Individual exports for testing
export function* getCampaigns() {
    try {
        const { rows, filterMetadata } = yield call(getAllCampaigns, 0, -1, {
            sort: [
                {
                    column: 'start_date',
                    operation: 'desc',
                },
                {
                    column: 'end_date',
                    operation: 'asc',
                },
            ],
        });

        yield put(campaignsLoaded(rows, filterMetadata));
    } catch (error) {
        yield put(campaignsLoadingError(error));
    }
}

function* createCampaignGroupSaga({ userId, groupName, groupType, campaignIds }) {
    try {
        const groupData = yield call(createCampaignGroupApi, {
            userId,
            groupName,
            groupType,
            campaignIds,
        });

        if (groupData.status === 2) {
            throw new Error(groupData.msg);
        } else {
            yield put(createCampaignGroupSuccess(groupData));
        }
    } catch (error) {
        yield put(createCampaignGroupError(error));
    }
}

function* deleteCampaignGroupSaga({ groupId }) {
    try {
        /* const groupData = */ yield call(deleteCampaignGroupApi, groupId);
        yield put(deleteCampaignGroupSuccess(groupId));
    } catch (error) {
        yield put(deleteCampaignGroupError(error));
    }
}

function* updateCampaignGroupSaga({ group }) {
    try {
        if (!group) {
            throw new Error('Campaign group not existed');
        } else {
            const groupPayload = {
                ...group,
            };
            const response = yield call(updateCampaignGroupApi, groupPayload);
            if (response.status === 0) {
                yield put(updateCampaignGroupSuccess(groupPayload));
            } else {
                throw new Error({ message: response.msg });
            }
        }
    } catch (error) {
        yield put(updateCampaignGroupError(error));
    }
}

function* getCampaignGroupsSaga({ userId, groupType }) {
    try {
        const groupsData = yield call(getCampaignGroupListByUserApi, userId, groupType);

        yield put(getCampaignGroupsSuccess(compact(groupsData.rows)));
    } catch (error) {
        yield put(getCampaignGroupsError(error));
    }
}

function* addCampaignsToGroupSaga({ userId, groupName, groupType, campaignIds }) {
    try {
        const campaignGroups = yield select(makeSelectCampaignGroups());

        // Find the group from given name and type. Otherwise, it will create a new group.
        let group = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName.toLowerCase() === groupName.toLowerCase() &&
                campaignGroup.groupType === groupType,
        );

        if (!group) {
            group = yield call(createCampaignGroupApi, {
                userId,
                groupName,
                groupType,
                campaignIds,
            });
            yield put(createCampaignGroupSuccess(group));
            yield put(addCampaignsToGroupSuccess(group));
        } else {
            const { groupId, shared } = group;
            const newCampaignIds = uniq(concat(group.campaignIds, campaignIds));
            const response = yield call(updateCampaignGroupApi, {
                userId,
                groupId,
                groupName,
                groupType,
                campaignIds: newCampaignIds,
                shared,
            });
            if (response.status === 0) {
                const groupData = {
                    groupId,
                    userId,
                    groupName,
                    groupType,
                    campaignIds: newCampaignIds,
                };
                yield put(addCampaignsToGroupSuccess(groupData));
            } else {
                throw new Error({ message: response.msg });
            }
        }
    } catch (error) {
        yield put(addCampaignsToGroupError(error));
    }
}

function* shareCampaignGroupSaga({ groupId, shared }) {
    try {
        const campaignGroups = yield select(makeSelectCampaignGroups());

        // Find the group from given name and type. Otherwise, it will create a new group.
        const group = campaignGroups.find((campaignGroup) => campaignGroup.groupId === groupId);
        if (!group) {
            throw new Error('Campaign group not existed');
        } else {
            const groupPayload = {
                ...group,
                shared,
            };
            const response = yield call(updateCampaignGroupApi, groupPayload);
            if (response.status === 0) {
                yield put(shareCampaignGroupSuccess(groupPayload));
            } else {
                throw new Error({ message: response.msg });
            }
        }
    } catch (error) {
        yield put(shareCampaignGroupError(error));
    }
}

// eslint-disable-next-line generator-star-spacing
function* removeCampaignsFromGroupSaga({ userId, groupName, groupType, campaignIds }) {
    try {
        const campaignGroups = yield select(makeSelectCampaignGroups());

        // Find the group from given name and type. Otherwise, it will create a new group.
        const group = campaignGroups.find(
            (campaignGroup) =>
                campaignGroup.groupName.toLowerCase() === groupName.toLowerCase() &&
                campaignGroup.groupType === groupType,
        );

        if (group) {
            const { groupId } = group;
            const newCampaignIds = uniq(difference(group.campaignIds, campaignIds));
            const response = yield call(updateCampaignGroupApi, {
                userId,
                groupId,
                groupName,
                groupType,
                campaignIds: newCampaignIds,
            });
            if (response.status === 0) {
                const groupData = {
                    groupId,
                    userId,
                    groupName,
                    groupType,
                    campaignIds: newCampaignIds,
                };

                yield put(removeCampaignsFromGroupSuccess(groupData));
            } else {
                throw new Error({ message: response.msg });
            }
        } else {
            throw new Error('No group found');
        }
    } catch (error) {
        yield put(addCampaignsToGroupError(error));
    }
}

function* getSharedCampaignGroupsSaga({ groupType }) {
    try {
        const groupsData = yield call(getSharedCampaignGroupListApi, groupType);

        yield put(getSharedCampaignGroupsSuccess(compact(groupsData.rows)));
    } catch (error) {
        yield put(getSharedCampaignGroupsError(error));
    }
}

function* getFilterSaga({ userId }) {
    try {
        const filters = yield call(fetchSavedFilters, userId);
        yield put(getFiltersSuccess(filters.campaignFilters));
        yield put(getSummaryDashboardFiltersSuccess(filters.summaryDashboardFilters));
    } catch (error) {
        yield put(getFiltersError(error));
    }
}

export function* addFilterSaga({ userId, filterName, filter }) {
    try {
        yield call(addFilter, userId, filterName, filter);
        if (filterName === '@@SUMMARY') {
            yield put(addSummaryDashboardFilterSuccess(filter));
        } else {
            yield put(addFilterSuccess(filter));
        }
    } catch (error) {
        yield put(addFilterError(error));
        if (error instanceof FilterExistsError) {
            yield call(updateFilterSaga, { userId, filterName, filter });
        }
    }
}

function* updateFilterSaga({ userId, filterName, filter }) {
    try {
        yield call(updateFilter, userId, filterName, filter);
        if (filterName === '@@SUMMARY') {
            yield put(updateSummaryDashboardFilterSuccess(filterName, filter));
        } else {
            yield put(updateFilterSuccess(filterName, filter));
        }
    } catch (error) {
        yield put(updateFilterError(error));
    }
}

/**
 * Root saga manages watcher lifecycle
 *
 * @returns {function} generator
 */
export default function* campaignData() {
    // Watches for LOAD_CAMPAIGNS actions and calls getCampaigns when one comes in.
    // By using `takeLatest` only the result of the latest API call is applied.
    // It returns task descriptor (just like fork) so we can continue execution
    // It will be cancelled automatically on component unmount
    yield takeLatest(LOAD_CAMPAIGNS, getCampaigns);
    yield takeLatest(CREATE_CAMPAIGN_GROUP, createCampaignGroupSaga);
    yield takeLatest(DELETE_CAMPAIGN_GROUP, deleteCampaignGroupSaga);
    yield takeLatest(UPDATE_CAMPAIGN_GROUP, updateCampaignGroupSaga);
    yield takeLatest(GET_CAMPAIGN_GROUPS, getCampaignGroupsSaga);
    yield takeLatest(ADD_CAMPAIGNS_TO_GROUP, addCampaignsToGroupSaga);
    yield takeLatest(SHARE_CAMPAIGN_GROUP, shareCampaignGroupSaga);
    yield takeLatest(REMOVE_CAMPAIGNS_FROM_GROUP, removeCampaignsFromGroupSaga);
    yield takeLatest(GET_SHARED_CAMPAIGN_GROUPS, getSharedCampaignGroupsSaga);
    yield takeLeading(GET_FILTERS, getFilterSaga);
    yield takeLatest(ADD_FILTER, addFilterSaga);
    yield takeLatest(UPDATE_FILTER, updateFilterSaga);
}
