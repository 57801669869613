import moment from 'moment';
import request, {
    formatGetOptions as getOptions,
    formatPutOptions as putOptions,
    formatPostOptions as postOptions,
} from '../../utils/request';
import { adGroupListSchema } from '../api-service/maps';
import { BASE_URL_V1 } from '../api-service/Url_Constants';
import { responseMapping } from '../api-helpers/responseMapping';
import { getCampaignDetails } from './campaign-creation';

export const getAdGroupDetails = async (accountId) => {
    const options = getOptions();
    const campaignDetails = await getCampaignDetails(accountId);
    const campaignId = campaignDetails[0]?.campaignId;
    if (!campaignId) {
        throw new Error('Campaign ID not found');
    }

    const { payload } = await request(
        `${BASE_URL_V1}/campaignMgmt/accounts/${accountId}/campaigns/${campaignId}/adGroups`,
        options,
    );
    return responseMapping(adGroupListSchema, payload);
};

export const saveAdGroupDetails = async (accountId, data) => {
    const payload = {
        name: data.name,
        startIsoDateTime: moment.utc(data.startDate).toISOString(),
        endIsoDateTime: moment.utc(data.endDate).toISOString(),
        budgets: [
            {
                budgetType: 'DAILY',
                isAutoPacing: data.dailyBudgetOption === 'automated',
                budget: data.dailyBudgetOption === 'set' ? data.dailyBudgetAmount.replaceAll(',', '') : null,
            },
            {
                budgetType: 'MONTHLY',
                isAutoPacing: false,
                budget: data.monthlyBudgetOption === 'set' ? data.monthlyBudgetAmount.replaceAll(',', '') : null,
            },
            {
                budgetType: 'LIFETIME',
                isAutoPacing: false,
                budget: data.lifetimeBudgetOption === 'capped' ? data.lifetimeBudgetAmount.replaceAll(',', '') : null,
            },
        ],
    };
    const campaignDetails = await getCampaignDetails(accountId);
    const campaignId = campaignDetails[0]?.campaignId;

    if (!campaignId) {
        throw new Error('Campaign ID not found');
    }

    if (data.adGroupId) {
        const options = putOptions(payload);
        return request(
            `${BASE_URL_V1}/campaignMgmt/accounts/${accountId}/campaigns/${campaignId}/adGroups/${data.adGroupId}`,
            options,
        );
    }
    const options = postOptions(payload);
    return request(`${BASE_URL_V1}/campaignMgmt/accounts/${accountId}/campaigns/${campaignId}/adGroups`, options);
};
