function validate(values, validationSchema) {
    try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
    } catch (err) {
        const errors = {};
        err.inner.forEach((error) => {
            errors[error.path] = error.message;
        });
        return errors;
    }
}

export { validate };
