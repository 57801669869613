import React, { useCallback, useState } from 'react';
import { currencyColumn, imageColumn, textColumn } from 'components/LightweightTable/columns';
import { Drawer, makeStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchProducts from '../SearchProducts';
import LightweightTable from '../../../../components/LightweightTable';
import AddButton from '../../../../components/AddButton';
import EmptyState from '../../shared/EmptyState';

const useStyles = makeStyles(() => ({
    root: {
        padding: '2rem',
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '1rem 0 3rem 0',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    imageCell: {
        minWidth: '75px',
        minHeight: '75px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        '& > img': {
            maxWidth: '75px',
            maxHeight: '75px',
        },
    },
    descriptionCell: {
        maxWidth: '400px',
    },
    addButton: {
        marginTop: '16px',
    },
    backdrop: {
        backgroundColor: 'grey',
    },
    paperProps: {
        width: '60%',
    },
}));

export function CreateProducts({ products }) {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const productList = products.get();
    const productsWithKeys = productList.map((product) => ({ ...product, key: product.sku }));

    const columns = [
        imageColumn('image', 'Image', { cellClassName: classes.imageCell }),
        textColumn('description', 'Description', { lineBreak: true, cellClassName: classes.descriptionCell }),
        textColumn('sku', 'SKU'),
        textColumn('bsin', 'BSIN'),
        textColumn('color', 'Color'),
        textColumn('upc', 'UPC'),
        textColumn('class', 'Class'),
        textColumn('category', 'Category'),
        currencyColumn('price', 'Price'),
        textColumn('status', 'Status'),
    ];

    const emptyStateData = {
        heading: 'Add Products',
        subtitle:
            'Add products to the line item to specify what you are promoting. ' +
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. ' +
            'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    };

    const emptyState = () => {
        if (productList.length === 0) {
            return <EmptyState content={emptyStateData} buttonLabel="Products" handleOpen={handleOpen} />;
        }
        return null;
    };

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleAddProducts = (newProducts) => {
        products.add(newProducts);
        handleClose();
    };

    const handleRemoveProducts = (item) => {
        products.remove(item.sku);
        const updatedProducts = productList.filter((product) => product.sku !== item.sku);
        products.save(updatedProducts);
    };

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography component="h2" align="left" className={classes.headline}>
                    Products ({productList?.length})
                </Typography>
                {productList.length > 0 && (
                    <AddButton label="Products" onClick={handleOpen} className={classes.addButton} />
                )}
            </div>
            <LightweightTable
                data={productsWithKeys}
                columns={columns}
                showRemoveIcon
                onRemoveRow={handleRemoveProducts}
            />
            <Drawer
                open={open}
                onClose={handleClose}
                variant="temporary"
                anchor="right"
                BackdropProps={{ className: classes.backdrop }}
                PaperProps={{ className: classes.paperProps }}
            >
                <SearchProducts onAddProducts={handleAddProducts} existingProducts={productList} />
            </Drawer>
            {emptyState()}
        </div>
    );
}

CreateProducts.propTypes = {
    products: PropTypes.object,
};

export default CreateProducts;
