import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_SESSION_ID_REQUEST, GET_SESSION_ID_SUCCESS, GET_SESSION_ID_FAILURE } from './constants';
import { getPaymentSessionId } from '../../api/billing-service';

function* fetchSessionIdSaga(action) {
    try {
        const data = yield call(getPaymentSessionId, action.payload);

        if (data.body?.paymentSessionId) {
            yield put({ type: GET_SESSION_ID_SUCCESS, payload: data.body.paymentSessionId });
        } else {
            yield put({ type: GET_SESSION_ID_FAILURE, error: data.error || 'Missing session ID' });
        }
    } catch (error) {
        yield put({ type: GET_SESSION_ID_FAILURE, error: error.message });
    }
}

export default function* billingSaga() {
    yield takeLatest(GET_SESSION_ID_REQUEST, fetchSessionIdSaga);
}
