import React from 'react';
import PropTypes from 'prop-types';

const CircleNumbers = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
    SIX: 6,
};

const numberOne = (
    <path d="M11.532 9.062L9.47404 10.434V8.628L11.784 6.99H13.632V17H11.532V9.062Z" className="numberCirclePath2" />
);
const numberTwo = (
    <path
        d="M8.26895 15.642L11.6149 12.646C12.1469 12.1607 12.5716 11.6987 12.8889 11.26C13.2063 10.812 13.3649 10.3453 13.3649 9.86C13.3649 9.42133 13.2436 9.08067 13.0009 8.838C12.7583 8.586 12.4083 8.46 11.9509 8.46C11.4843 8.46 11.1063 8.60933 10.8169 8.908C10.5369 9.19733 10.3549 9.68267 10.2709 10.364H8.36695C8.43228 9.23467 8.78228 8.36667 9.41695 7.76C10.0609 7.15333 10.9336 6.85 12.0349 6.85C12.5763 6.85 13.0569 6.92467 13.4769 7.074C13.8969 7.214 14.2516 7.41933 14.5409 7.69C14.8303 7.95133 15.0496 8.26867 15.1989 8.642C15.3576 9.006 15.4369 9.412 15.4369 9.86C15.4369 10.532 15.2316 11.1667 14.8209 11.764C14.4196 12.352 13.8456 12.954 13.0989 13.57L10.9429 15.404H15.4509V17H8.26895V15.642Z"
        className="numberCirclePath2"
    />
);
const numberThree = (
    <path
        d="M11.9233 17.154C11.3073 17.154 10.7613 17.0747 10.2853 16.916C9.80933 16.7573 9.40799 16.538 9.08133 16.258C8.76399 15.9687 8.51666 15.6233 8.33933 15.222C8.17133 14.8113 8.07799 14.3587 8.05933 13.864H9.93533C9.98199 14.3587 10.1547 14.7693 10.4533 15.096C10.752 15.4227 11.2187 15.586 11.8533 15.586C12.46 15.586 12.9033 15.4553 13.1833 15.194C13.4727 14.9233 13.6173 14.55 13.6173 14.074C13.6173 13.0567 13.0527 12.548 11.9233 12.548H11.1533V11.12H11.8673C12.418 11.12 12.8193 11.0033 13.0713 10.77C13.3327 10.5273 13.4633 10.182 13.4633 9.734C13.4633 9.342 13.342 9.02467 13.0993 8.782C12.8567 8.53933 12.488 8.418 11.9933 8.418C11.4893 8.418 11.088 8.558 10.7893 8.838C10.4907 9.118 10.3133 9.496 10.2573 9.972H8.40933C8.43733 9.524 8.53533 9.11333 8.70333 8.74C8.88066 8.35733 9.12333 8.026 9.43133 7.746C9.73933 7.466 10.1127 7.24667 10.5513 7.088C10.99 6.92933 11.4847 6.85 12.0353 6.85C12.5673 6.85 13.0433 6.92 13.4633 7.06C13.8927 7.2 14.252 7.39133 14.5413 7.634C14.8307 7.87667 15.05 8.16133 15.1993 8.488C15.358 8.81467 15.4373 9.16933 15.4373 9.552C15.4373 10.1213 15.288 10.5833 14.9893 10.938C14.6907 11.2927 14.294 11.554 13.7993 11.722C14.3873 11.8527 14.8587 12.1233 15.2133 12.534C15.568 12.9353 15.7453 13.4627 15.7453 14.116C15.7453 14.5827 15.6567 15.0027 15.4793 15.376C15.302 15.7493 15.05 16.0713 14.7233 16.342C14.3967 16.6033 13.9953 16.804 13.5193 16.944C13.0433 17.084 12.5113 17.154 11.9233 17.154Z"
        className="numberCirclePath2"
    />
);
const numberFour = (
    <path
        d="M12.7806 14.998H8.02061V13.486L12.2066 6.99H14.7966V13.486H16.1406V14.998H14.7966V17H12.7806V14.998ZM9.78461 13.486H12.8226V8.684L9.78461 13.486Z"
        className="numberCirclePath2"
    />
);
const numberFive = (
    <path
        d="M11.85 17.154C11.2434 17.154 10.716 17.0747 10.268 16.916C9.82938 16.7573 9.46071 16.5427 9.16205 16.272C8.86338 15.992 8.63471 15.6653 8.47605 15.292C8.32671 14.9093 8.23805 14.4987 8.21005 14.06H10.128C10.1654 14.284 10.2167 14.4893 10.282 14.676C10.3567 14.8533 10.4547 15.0073 10.576 15.138C10.7067 15.2687 10.8747 15.3713 11.08 15.446C11.2854 15.5113 11.5374 15.544 11.836 15.544C12.3774 15.544 12.788 15.3713 13.068 15.026C13.3574 14.6807 13.502 14.2233 13.502 13.654C13.502 13.0847 13.3527 12.6367 13.054 12.31C12.7647 11.974 12.3494 11.806 11.808 11.806C11.4067 11.806 11.0707 11.8807 10.8 12.03C10.5294 12.17 10.3194 12.3473 10.17 12.562H8.54605L8.84005 6.99H15.028V8.6H10.45L10.31 11.232C10.5154 10.98 10.7954 10.7653 11.15 10.588C11.5047 10.4107 11.962 10.322 12.522 10.322C12.9794 10.322 13.3994 10.4013 13.782 10.56C14.1647 10.7093 14.4914 10.9287 14.762 11.218C15.0327 11.498 15.2427 11.848 15.392 12.268C15.5414 12.6787 15.616 13.15 15.616 13.682C15.616 14.214 15.5227 14.6947 15.336 15.124C15.1587 15.544 14.902 15.908 14.566 16.216C14.2394 16.5147 13.8427 16.748 13.376 16.916C12.9187 17.0747 12.41 17.154 11.85 17.154Z"
        className="numberCirclePath2"
    />
);

const numberSix = (
    <path
        d="M12.1643 17.154C11.4176 17.154 10.7876 17.0327 10.2743 16.79C9.76095 16.5473 9.3456 16.216 9.02828 15.796C8.71095 15.3667 8.48228 14.8627 8.34228 14.284C8.20228 13.7053 8.13228 13.08 8.13228 12.408V12.156C8.13228 11.3067 8.23495 10.5507 8.44028 9.888C8.65495 9.22533 8.94895 8.67 9.32228 8.222C9.70495 7.774 10.1529 7.43333 10.6663 7.2C11.1889 6.96667 11.7536 6.85 12.3603 6.85C13.3216 6.85 14.0963 7.07867 14.6843 7.536C15.2723 7.984 15.6223 8.66067 15.7343 9.566H13.8023C13.7276 9.21133 13.5829 8.936 13.3683 8.74C13.1536 8.544 12.8176 8.446 12.3603 8.446C11.7163 8.446 11.2029 8.726 10.8203 9.286C10.4376 9.83667 10.2323 10.6487 10.2043 11.722C10.4376 11.3487 10.7503 11.05 11.1423 10.826C11.5436 10.5927 12.0476 10.476 12.6543 10.476C13.1303 10.476 13.5689 10.5553 13.9703 10.714C14.3716 10.8633 14.7169 11.078 15.0063 11.358C15.3049 11.638 15.5336 11.9787 15.6923 12.38C15.8603 12.772 15.9443 13.2153 15.9443 13.71C15.9443 14.2233 15.8556 14.6947 15.6783 15.124C15.5103 15.544 15.2629 15.9033 14.9363 16.202C14.6096 16.5007 14.2129 16.734 13.7463 16.902C13.2796 17.07 12.7523 17.154 12.1643 17.154ZM12.1503 15.628C12.6729 15.628 13.0929 15.4553 13.4103 15.11C13.7276 14.7647 13.8863 14.312 13.8863 13.752C13.8863 13.192 13.7276 12.7393 13.4103 12.394C13.1023 12.0487 12.6776 11.876 12.1363 11.876C11.8656 11.876 11.6183 11.9227 11.3943 12.016C11.1796 12.1 10.9929 12.2213 10.8343 12.38C10.6849 12.5387 10.5636 12.73 10.4703 12.954C10.3863 13.1687 10.3443 13.402 10.3443 13.654C10.3443 14.2793 10.5076 14.7647 10.8343 15.11C11.1703 15.4553 11.6089 15.628 12.1503 15.628Z"
        className="numberCirclePath2"
    />
);

const getCircleNumber = (circleNumber) => {
    switch (circleNumber) {
        case CircleNumbers.ONE:
            return numberOne;
        case CircleNumbers.TWO:
            return numberTwo;
        case CircleNumbers.THREE:
            return numberThree;
        case CircleNumbers.FOUR:
            return numberFour;
        case CircleNumbers.FIVE:
            return numberFive;
        case CircleNumbers.SIX:
            return numberSix;
        default:
            return numberOne;
    }
};

const CircleWithNumbersSvg = ({ width = 24, height = 24, circleNumber }) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M 0,12 a 12, 12 0 1,1 24, 0 12, 12 0 1,1 -24, 0" className="numberCirclePath1" />
        {getCircleNumber(circleNumber)}
    </svg>
);

CircleWithNumbersSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    circleNumber: PropTypes.number,
};

export default CircleWithNumbersSvg;
