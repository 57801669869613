import React, { useState, useCallback, useRef, useMemo } from 'react';
import { TextField, Button, Typography, makeStyles } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LightweightTable from 'components/LightweightTable';
import { currencyColumn, textColumn, imageColumn } from 'components/LightweightTable/columns';
import PropTypes from 'prop-types';
import { useSponsoredCreation } from '../../shared/useSponsoredCreation';
import createProductSearch from './createProductSearch';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2, 4, 3),
    },
    searchBar: {
        marginTop: '10px',
        display: 'flex',
        alignItems: 'center',
    },
    searchField: {
        width: '760px',
        zIndex: 3,
        transition: '.25s width',
        '&[data-focus="true"]': {
            width: '400px',
        },
        marginRight: '16px',
    },
    tablePlaceholder: {
        marginTop: theme.spacing(2),
        fontWeight: 'bold',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    headline: {
        margin: '0',
        fontSize: '28px',
        fontFamily: 'HumanBBYDigital, sans-serif',
    },
    findButton: {
        marginRight: '16px',
        textTransform: 'capitalize',
    },
    fabButton: {
        backgroundColor: '#0146BE',
        color: '#ffffff',
        boxShadow: 'none',
        textTransform: 'capitalize',
        '& .MuiSvgIcon-root': {
            margin: '0 5px 0 0',
            fontSize: 'medium',
        },
        borderRadius: '20px',
    },
    addProductButton: {
        marginTop: '16px',
        width: '960px',
        textTransform: 'capitalize',
    },
    descriptionCell: {
        maxWidth: '200px',
    },
}));

export const SearchProducts = ({ onAddProducts, existingProducts }) => {
    const classes = useStyles();

    const { searchProducts } = useSponsoredCreation();
    const searchProductList = searchProducts.get();
    const productSearch = useMemo(() => createProductSearch(searchProductList), [searchProductList]);

    const [searchKeyword, setSearchKeyword] = useState('');
    const [tableData, setTableData] = useState([]);
    const [isTablePopulated, setIsTablePopulated] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [autocompleteOptions, setAutocompleteOptions] = useState([]);
    const [allSelected, setAllSelected] = useState(false);

    const debounceTimeout = useRef(null);
    const currentSearchTerm = useRef('');

    const handleSearch = useCallback(() => {
        const filteredData = productSearch(searchKeyword);
        setTableData(filteredData.map((result) => result.item));
        setIsTablePopulated(filteredData.length > 0);
    }, [productSearch, searchKeyword]);

    const handleInputChange = useCallback(
        (event, value) => {
            setSearchKeyword(value);
            const filteredOptions = productSearch(value);
            setAutocompleteOptions(filteredOptions.map((result) => result.item));
        },
        [productSearch],
    );

    const performDebouncedSearch = (event) => {
        currentSearchTerm.current = event.target.value;
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }

        const runFunc = () => {
            debounceTimeout.current = null;
            const searchResults = productSearch(event.target.value);
            setAutocompleteOptions(searchResults.map((result) => result.item));
            return searchResults;
        };

        debounceTimeout.current = setTimeout(runFunc, 200);
    };

    const handleAddProducts = () => {
        const selectedProducts = tableData.filter(
            (product) => selectedRows.includes(product.sku) && !existingProducts.some((p) => p.sku === product.sku),
        );
        onAddProducts(selectedProducts);
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedRows([]);
        } else {
            setSelectedRows(tableData.map((product) => product.sku));
        }
        setAllSelected(!allSelected);
    };

    const columns = [
        imageColumn('image', 'Image', { cellClassName: classes.imageCell }),
        textColumn('description', 'Description', { lineBreak: true, cellClassName: classes.descriptionCell }),
        textColumn('sku', 'SKU'),
        textColumn('bsin', 'BSIN'),
        textColumn('color', 'Color'),
        textColumn('upc', 'UPC'),
        currencyColumn('price', 'Price'),
        textColumn('status', 'Status'),
    ];

    return (
        <div className={classes.paper}>
            <Typography component="h2" align="left" className={classes.headline}>
                Search Products
            </Typography>
            <div className={classes.searchBar}>
                <Autocomplete
                    freeSolo
                    options={autocompleteOptions}
                    getOptionLabel={(option) => option.description || ''}
                    inputValue={searchKeyword}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            className={classes.searchField}
                            placeholder="Search by products by SKU, Keywords, and Product Name..."
                            size="small"
                            variant="outlined"
                            inputProps={{
                                ...params.inputProps,
                                className: classes.searchField,
                            }}
                            onChange={performDebouncedSearch}
                        />
                    )}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    className={classes.findButton}
                    disabled={!searchKeyword}
                >
                    Find
                </Button>
                <Button
                    variant="contained"
                    className={classes.fabButton}
                    onClick={handleSelectAll}
                    disabled={!searchKeyword || tableData.length === 0}
                >
                    {allSelected ? 'Clear All' : 'Select All'}
                </Button>
            </div>
            <LightweightTable
                data={tableData}
                columns={columns}
                selectedRows={selectedRows}
                onSelectRow={setSelectedRows}
                selectable
            />
            {!isTablePopulated && (
                <Typography className={classes.tablePlaceholder}>
                    Use the Search Field to find Product Name, Keywords, and SKU ID
                </Typography>
            )}
            <Button
                variant="contained"
                color="primary"
                disabled={selectedRows.length === 0}
                className={classes.addProductButton}
                onClick={handleAddProducts}
            >
                Add Products
            </Button>
        </div>
    );
};

SearchProducts.propTypes = {
    onAddProducts: PropTypes.func.isRequired,
    existingProducts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SearchProducts;
