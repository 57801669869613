export const KeywordData = {
    keywords: [
        {
            subTitle: `Keywords (Exact Match)`,
            name: 'keywords',
        },
    ],
    negativeKeywords: [
        {
            subTitle: `Broad Match Negative Keywords`,
            name: 'negativeKeywordsBroadMatch',
        },
        {
            subTitle: `Exact Match Negative Keywords`,
            name: 'negativeKeywordsExactMatch',
        },
    ],
};

export const KeywordTabData = {
    KEYWORDS: {
        label: 'Keywords',
        key: 'KEYWORDS',
    },
    NEGATIVE_KEYWORDS: {
        label: 'Negative Keywords',
        key: 'NEGATIVE_KEYWORDS',
    },
};

export const KeywordsEmptyState = {
    keywords: {
        heading: 'Add Keywords',
        subtitle:
            'You can "Skip" adding manual keywords right now. ' +
            'Your ads will show automatically on keywords relevant to your products. ' +
            'While you can submit Exact match keywords, ' +
            'we recommend waiting at least 7-10 days after your ads start serving to add keywords.',
    },
    negativeKeywords: {
        heading: 'Negative Keywords',
        subtitle:
            'You can skip adding Negative Keywords now and add them later. ' +
            'Our guidance is to wait 7-10 days after your ads start serving. ' +
            'Negative keywords will exclude your ads from appearing on searches that may be relevant to your products.',
    },
};
