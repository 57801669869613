/**
 * Create the store with dynamic reducers
 */

import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import history from 'utils/history';
import rootSaga from './rootSaga';
import createReducer from './reducers';

export default function configureRedux(initialState, _history) {
    let reduxSagaMonitorOptions = {};

    // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
    /* istanbul ignore next */
    if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
        // NOTE: Uncomment the code below to restore support for Redux Saga
        // Dev Tools once it supports redux-saga version 1.x.x
        if (window.__SAGA_MONITOR_EXTENSION__)
            reduxSagaMonitorOptions = {
                sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
            };
        /* eslint-enable */
    }

    const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
    const { run: runSaga } = sagaMiddleware;

    // Create the store with two middlewares
    // 1. sagaMiddleware: Makes redux-sagas work
    // 2. routerMiddleware: Syncs the location/URL path to the state
    const middlewares = [sagaMiddleware, routerMiddleware(_history)];

    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line global-require
        const { logger } = require('redux-logger');

        middlewares.push(logger);
    }

    const enhancers = [
        createInjectorsEnhancer({
            createReducer,
            runSaga,
        }),
    ];

    // Make reducers hot reloadable, see http://mxs.is/googmo
    /* istanbul ignore next */
    if (module.hot) {
        module.hot.accept('./reducers', () => {
            forceReducerReload(store);
        });
    }

    const store = configureStore({
        reducer: createReducer(),
        middleware: middlewares,
        preloadedState: initialState,
        enhancers,
    });

    sagaMiddleware.run(rootSaga);

    return store;
}

export const appStore = configureRedux({}, history);
