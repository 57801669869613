import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { campaignDefaults } from '../componentData/CampaignManagementDefaults';
import useCampaignSettings from './useCampaignSettings';
import useAdGroup from './useAdGroup';

const validationSchema = yup.object();

const splitStringValueAtCarriage = (string) => string.split(/\r\n|\r|\n/);

const wordsToAdd = (string, type, wordType) => {
    if (string) {
        const newWords = [];
        const splitWords = splitStringValueAtCarriage(string);
        // remove duplicates by using Set data structure
        const wordSet = new Set(splitWords);
        wordSet.forEach((word) => {
            if (wordType === 'negativeKeyword' && word) {
                newWords.push({
                    negativeKeywords: word,
                    matchType: type,
                    key: `${word}-${type}`,
                });
            }
            if (wordType === 'keyword' && word) {
                newWords.push({
                    keywords: word,
                    matchType: type,
                    key: `${word}-${type}`,
                });
            }
        });
        return newWords;
    }
    return null;
};

const params = {
    initialForms: {
        products: campaignDefaults.products,
        searchProducts: campaignDefaults.searchProducts,
        bidding: campaignDefaults.bidding,
        keywords: campaignDefaults.keywords,
        negativeKeywords: campaignDefaults.negativeKeywords,
        review: campaignDefaults.review,
    },
    validate: (values) => {
        try {
            validationSchema.validateSync(values, { abortEarly: false });
            return {};
        } catch (err) {
            const errors = {};
            err.inner.forEach((error) => {
                errors[error.path] = error.message;
            });
            return errors;
        }
    },
};

export const useSponsoredCreation = () => {
    const campaignSettings = useCampaignSettings();
    const adGroup = useAdGroup();
    const { initialForms, validate } = params;

    const [forms, setForms] = useState(initialForms);

    const load = useCallback(async () => {
        // eslint-disable-next-line no-console
        console.log('Loading data...');
    }, []);

    const get = useCallback((tab) => forms[tab] || {}, [forms]);

    const add = useCallback((tab, newItems) => {
        setForms((prevForms) => {
            let itemsToAdd = [];
            const currentItems = prevForms[tab];
            const existingIdentifiers = currentItems.map((item) => {
                if (tab === 'keywords') {
                    return item.key;
                }
                if (tab === 'negativeKeywords') {
                    return item.key;
                }
                return item.sku;
            });

            if (tab === 'keywords') {
                const keywords = wordsToAdd(newItems.keywords, 'Exact', 'keyword');
                if (keywords) {
                    itemsToAdd = [...keywords];
                }
            }

            if (tab === 'negativeKeywords') {
                const negativeKeywordsBroadMatch = wordsToAdd(
                    newItems.negativeKeywordsBroadMatch,
                    'Broad',
                    'negativeKeyword',
                );
                const negativeKeywordsExactMatch = wordsToAdd(
                    newItems.negativeKeywordsExactMatch,
                    'Exact',
                    'negativeKeyword',
                );

                if (negativeKeywordsBroadMatch) {
                    itemsToAdd = [...negativeKeywordsBroadMatch];
                }

                if (negativeKeywordsExactMatch) {
                    itemsToAdd = [...itemsToAdd, ...negativeKeywordsExactMatch];
                }
            }

            // filter for existing words to avoid duplicates of existing words
            const filteredUniqueItems = itemsToAdd.filter((item) => {
                if (tab === 'keywords') {
                    return !existingIdentifiers.includes(item.key);
                }
                if (tab === 'negativeKeywords') {
                    return !existingIdentifiers.includes(item.key);
                }
                return !existingIdentifiers.includes(item.sku);
            });

            return {
                ...prevForms,
                [tab]: [...currentItems, ...filteredUniqueItems],
            };
        });
    }, []);

    const remove = useCallback((tab, identifier) => {
        setForms((prevForms) => {
            const updatedList = prevForms[tab].filter((item) => {
                if (tab === 'keywords') {
                    return item.keywords !== identifier;
                }
                if (tab === 'negativeKeywords') {
                    return item.key !== identifier;
                }
                return item.sku !== identifier;
            });
            return {
                ...prevForms,
                [tab]: updatedList,
            };
        });
    }, []);

    const update = useCallback((tab, identifiers) => {
        setForms((prevForms) => ({
            ...prevForms,
            [tab]: prevForms[tab].map((item) => {
                const identifierKey = tab === 'keywords' ? 'keywords' : 'sku';
                const updateItem = identifiers.find((identifier) => item[identifierKey] === identifier[identifierKey]);
                return updateItem ? { ...item, ...updateItem } : item;
            }),
        }));
    }, []);

    const validateForm = useCallback((tab) => validate(forms[tab]), [validate, forms]);

    const save = useCallback(
        (tab, data) => {
            setForms((prevForms) => ({
                ...prevForms,
                [tab]: data,
            }));
            Object.keys(forms).forEach((key) => {
                // eslint-disable-next-line no-console
                console.log(`Data for ${key}:`, JSON.stringify(forms[key], null, 2));
            });
        },
        [forms],
    );

    const submit = useCallback(
        async (tab) => {
            const data = forms[tab];
            try {
                // eslint-disable-next-line no-console
                console.log('Submitting data:', data);
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error saving campaign data:', error);
            }
        },
        [forms],
    );

    return {
        campaignSettings,
        adGroup,
        products: {
            // load: () => load('products'),
            get: () => get('products'),
            add: (data) => add('products', data),
            remove: (data) => remove('products', data),
            update: (data) => update('products', data),
            save: (data) => save('products', data),
            isValid: () => validationSchema.isValidSync(forms.products),
            submit: () => submit('products'),
        },
        searchProducts: {
            get: () => get('searchProducts'),
        },
        bidding: {
            load: () => load('bidding'),
            get: () => get('bidding'),
            validateForm: () => validateForm('bidding'),
            save: (data) => save('bidding', data),
            isValid: () => validationSchema.isValidSync(forms.bidding),
            submit: () => submit('bidding'),
        },
        keywords: {
            // load: () => load('keywords'),
            get: () => get('keywords'),
            add: (data) => add('keywords', data),
            remove: (data) => remove('keywords', data),
            update: (data) => update('keywords', data),
            isValid: () => validationSchema.isValidSync(forms.keywords),
            save: (data) => save('keywords', data),
        },
        negativeKeywords: {
            get: () => get('negativeKeywords'),
            add: (data) => add('negativeKeywords', data),
            remove: (data) => remove('negativeKeywords', data),
            isValid: () => validationSchema.isValidSync(forms.negativeKeywords),
            save: (data) => save('negativeKeywords', data),
        },
        review: {
            // load: () => load('review'),
            get: () => get('review'),
            validateForm: () => validateForm('review'),
            save: (data) => save('review', data),
            submit: (data) => submit('review', data),
        },
    };
};
