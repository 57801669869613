import React from 'react';
import PropTypes from 'prop-types';
import {
    Checkbox,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ActionMenu from 'components/ActionMenu';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    removeButton: {
        color: 'red',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

function LightweightTable({
    columns,
    data,
    actions,
    selectable,
    selectedRows,
    onSelectRow,
    showRemoveIcon,
    onRemoveRow,
}) {
    const classes = useStyles();

    const handleSelectRow = (key) => {
        const selectedIndex = selectedRows.indexOf(key);
        let newSelectedRows = [];

        if (selectedIndex === -1) {
            newSelectedRows = [...selectedRows, key];
        } else {
            newSelectedRows = selectedRows.filter((selectedKey) => selectedKey !== key);
        }

        onSelectRow(newSelectedRows);
    };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {selectable && <TableCell padding="checkbox" />}
                        {columns?.map((column) => (
                            <TableCell key={column.field + column.label}>
                                <div className={column.headerClassName}>{column.label}</div>
                            </TableCell>
                        ))}
                        {(actions || showRemoveIcon) && <TableCell>Actions</TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data?.map((row) => (
                        <TableRow key={row.key}>
                            {selectable && (
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedRows.includes(row.key)}
                                        onChange={() => handleSelectRow(row.key)}
                                        color="primary"
                                    />
                                </TableCell>
                            )}
                            {columns?.map((column) => (
                                <TableCell key={`${column.field}-${row.key}`}>
                                    <div className={column.cellClassName}>
                                        {column.applyFormatting(row[column.field])}
                                    </div>
                                </TableCell>
                            ))}
                            {actions && (
                                <TableCell>
                                    <ActionMenu actions={actions} item={row} />
                                </TableCell>
                            )}
                            {showRemoveIcon && (
                                <TableCell>
                                    <IconButton className={classes.removeButton} onClick={() => onRemoveRow(row)}>
                                        <CloseIcon />
                                    </IconButton>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

LightweightTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            field: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        }),
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.func,
    selectable: PropTypes.bool,
    selectedRows: PropTypes.arrayOf(PropTypes.string),
    onSelectRow: PropTypes.func,
    showRemoveIcon: PropTypes.bool,
    onRemoveRow: PropTypes.func,
};

LightweightTable.defaultProps = {
    selectable: false,
    selectedRows: [],
};

export default LightweightTable;
