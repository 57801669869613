import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { useAuthedUser } from 'shared/hooks/useAuthedUser';
import { getAdGroupDetails, saveAdGroupDetails } from '../../../../api/create-sponsored-campaign/ad-group-creation';
import { campaignDefaults } from '../componentData/CampaignManagementDefaults';
import { validate } from '../utils';

const validationSchema = yup.object();

export default function useAdGroup() {
    const [adGroup, setAdGroup] = useState(campaignDefaults.adGroup);
    const user = useAuthedUser();
    const accountId = user?.orgAccounts[0];

    const load = useCallback(async () => {
        const response = await getAdGroupDetails(accountId);

        if (response.length > 0) {
            const savedSettings = response[0];

            const dailyBudgetInfo = savedSettings?.budgets?.find((budget) => budget.budgetType === 'daily');
            const monthlyBudgetInfo = savedSettings?.budgets?.find((budget) => budget.budgetType === 'monthly');
            const lifetimeBudgetInfo = savedSettings?.budgets?.find((budget) => budget.budgetType === 'lifetime');

            let dailyBudgetOption = 'none';
            if (dailyBudgetInfo?.isAutoPacing) {
                dailyBudgetOption = 'automated';
            } else if (dailyBudgetInfo?.budget !== null) {
                dailyBudgetOption = 'set';
            }

            setAdGroup((oldAdGroup) => ({
                ...oldAdGroup,
                ...savedSettings,
                dailyBudgetOption,
                monthlyBudgetOption: monthlyBudgetInfo?.budget === null ? 'none' : 'set',
                lifetimeBudgetOption: lifetimeBudgetInfo.budget === null ? 'uncapped' : 'capped',
                dailyBudgetAmount: dailyBudgetInfo?.budget === null ? 0 : dailyBudgetInfo?.budget,
                monthlyBudgetAmount: monthlyBudgetInfo?.budget === null ? 0 : monthlyBudgetInfo?.budget,
                lifetimeBudgetAmount: lifetimeBudgetInfo?.budget === null ? 0 : lifetimeBudgetInfo?.budget,
            }));
        }
    }, [accountId]);

    const get = useCallback(() => adGroup, [adGroup]);

    const validateForm = (values) => {
        validate(values, validationSchema);
    };

    const isValid = useCallback(() => validationSchema.isValidSync(adGroup), [adGroup]);

    const submit = useCallback(async () => {
        try {
            const validationErrors = validate(adGroup, validationSchema);
            if (Object.keys(validationErrors).length === 0) {
                await saveAdGroupDetails(accountId, adGroup);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error saving ad group data:', error);
        }
    }, [accountId, adGroup]);

    return {
        load,
        get,
        validateForm,
        isValid,
        save: setAdGroup,
        submit,
    };
}
