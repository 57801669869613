export const RadioNamesAndKeys = {
    budget: {
        name: 'budget',
        csKey: 'csBudgetKey',
        agKey: 'agBudgetKey',
        csRadioKey: 'csBudgetRadioKey',
        agRadioKey: 'agBudgetRadioKey',
        radioGroupName: 'lifetimeBudgetOption',
    },
    monthly: {
        name: 'monthly',
        csKey: 'csMonthlyKey',
        agKey: 'agMonthlyKey',
        csRadioKey: 'csMonthlyBudgetRadioKey',
        agRadioKey: 'agMonthlyBudgetRadioKey',
        radioGroupName: 'monthlyBudgetOption',
    },
    daily: {
        name: 'daily',
        csKey: 'csDailyKey',
        agKey: 'agDailyKey',
        csRadioKey: 'csDailyBudgetRadioKey',
        agRadioKey: 'agDailyBudgetRadioKey',
        radioGroupName: 'dailyBudgetOption',
    },
    optStrategy: {
        optStrategyRadioKey: 'optStrategyRadioKey',
        radioGroupName: 'optStrategyRadioGroup',
    },
    optBidding: {
        maxBidRadioKey: 'maxBidRadioKey',
        radioGroupName: 'maxBidRadioGroup',
    },
    textField: {
        radioValue: 'textFieldRadio',
    },
};
