import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import { FormControl, Select, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 14,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 15,
            whiteSpace: 'nowrap',
        },
    },
    selectIcon: {
        color: theme.palette.primary.main,
    },
    select: {
        height: '20px',
    },
}));

export function OptimizationStrategyFormControlSelect({ label, options, setOptStrategy }) {
    const classes = useStyles();
    const defaultValue = options[2]?.value;

    return (
        <Field name="optStrategy" type="select">
            {({ input }) => (
                <div>
                    <InputLabel className={classes.inputLabel}>{label}</InputLabel>
                    <FormControl margin="dense" variant="outlined" fullWidth component="fieldset">
                        <Select
                            id={`optStrategy_${label}`}
                            value={input.value || defaultValue}
                            onChange={(event) => {
                                input.onChange(event.target.value);
                                setOptStrategy(event.target.value);
                            }}
                            classes={{ icon: classes.selectIcon, root: classes.select }}
                        >
                            {options?.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            )}
        </Field>
    );
}

OptimizationStrategyFormControlSelect.propTypes = {
    label: PropTypes.string,
    options: PropTypes.array,
    setOptStrategy: PropTypes.func,
};

export default OptimizationStrategyFormControlSelect;
