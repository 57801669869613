import request, { formatGetOptions } from 'utils/request';
import env from 'config/env';
import { responseMapping } from './api-helpers/responseMapping';
import { billingAddressSchema } from './api-service/maps';

export async function fetchBillingAddress() {
    const options = formatGetOptions();
    const response = await request(`${env.GATEWAY_BASE_URL}/v1/billing/google/billingAddress`, options);
    return responseMapping(billingAddressSchema, response.body);
}

export async function getPaymentSessionId({ orgAccountId }) {
    const options = formatGetOptions();
    const url = `${env.GATEWAY_BASE_URL}/v1/billing/payment/component/session?orgAccountId=${orgAccountId}`;
    const response = await request(url, options);
    return response;
}
