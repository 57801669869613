import { RadioNamesAndKeys } from './RadioGroupNamesAndKeys';
import { TextFieldData } from './TextFieldData';

export const MaxBidOptions = [
    {
        radioTitleSection: {
            title: null,
            subTitle: null,
            name: 'maxBid',
            key: 'maxBidKey',
        },
        radioGroup: [
            {
                label: "Don't apply",
                subLabel: null,
                textField: false,
                textFieldKey: null,
                value: 'dontApply',
                name: RadioNamesAndKeys.optBidding.radioGroupName,
                key: RadioNamesAndKeys.optBidding.maxBidRadioKey,
            },
            {
                label: 'Apply max bid',
                subLabel: null,
                textField: true,
                textFieldKey: TextFieldData.optBidding.maxBidTextFieldKey,
                namesAndValues: {
                    textField: {
                        name: TextFieldData.optBidding.textFieldName,
                        value: '',
                    },
                    radio: {
                        name: RadioNamesAndKeys.optBidding.radioGroupName,
                        value: RadioNamesAndKeys.textField.radioValue,
                    },
                },
                key: RadioNamesAndKeys.optBidding.maxBidRadioKey,
            },
        ],
    },
];
