import { useCallback, useState } from 'react';
import * as yup from 'yup';
import { getCampaignDetails, saveCampaignDetails } from 'api/create-sponsored-campaign/campaign-creation';
import { useAuthedUser } from 'shared/hooks/useAuthedUser';
import { campaignDefaults } from '../componentData/CampaignManagementDefaults';
import { validate } from '../utils';

const validationSchema = yup.object();

export default function useCampaignSettings() {
    const [campaignSettings, setCampaignSettings] = useState(campaignDefaults.campaignSettings);
    const user = useAuthedUser();
    const accountId = user?.orgAccounts[0];

    const load = useCallback(async () => {
        const response = await getCampaignDetails(accountId);

        if (response.length > 0) {
            const savedSettings = response[0];

            const dailyBudgetInfo = savedSettings?.budgets?.find((budget) => budget.budgetType === 'daily');
            const monthlyBudgetInfo = savedSettings?.budgets?.find((budget) => budget.budgetType === 'monthly');
            const lifetimeBudgetInfo = savedSettings?.budgets?.find((budget) => budget.budgetType === 'lifetime');

            let dailyBudgetOption = 'none';
            if (dailyBudgetInfo?.isAutoPacing) {
                dailyBudgetOption = 'automated';
            } else if (dailyBudgetInfo?.budget !== null) {
                dailyBudgetOption = 'set';
            }

            const postClickAttributionInfo = savedSettings?.attributions?.find(
                (attribution) => attribution.attributionType === 'postclick',
            );
            const postViewAttributionInfo = savedSettings?.attributions?.find(
                (attribution) => attribution.attributionType === 'postview',
            );

            setCampaignSettings((oldCampaignSettings) => ({
                ...oldCampaignSettings,
                ...savedSettings,
                dailyBudgetOption,
                monthlyBudgetOption: monthlyBudgetInfo?.budget === null ? 'none' : 'set',
                lifetimeBudgetOption: lifetimeBudgetInfo.budget === null ? 'uncapped' : 'capped',
                dailyBudgetAmount: dailyBudgetInfo?.budget === null ? 0 : dailyBudgetInfo?.budget,
                monthlyBudgetAmount: monthlyBudgetInfo?.budget === null ? 0 : monthlyBudgetInfo?.budget,
                lifetimeBudgetAmount: lifetimeBudgetInfo?.budget === null ? 0 : lifetimeBudgetInfo?.budget,
                postClickAttributionWindow: postClickAttributionInfo?.attributionWindow,
                postClickAttributionScope: postClickAttributionInfo?.attributionScope,
                postViewAttributionWindow: postViewAttributionInfo?.attributionWindow,
                postViewAttributionScope: postViewAttributionInfo?.attributionScope,
            }));
        }
    }, [accountId]);

    const get = useCallback(() => campaignSettings, [campaignSettings]);

    const validateForm = (values) => {
        validate(values, validationSchema);
    };

    const isValid = useCallback(() => validationSchema.isValidSync(campaignSettings), [campaignSettings]);

    const submit = useCallback(async () => {
        try {
            if (isValid()) {
                await saveCampaignDetails(accountId, campaignSettings);
            }
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error saving campaign data:', error);
        }
    }, [accountId, campaignSettings, isValid]);

    return {
        load,
        get,
        validateForm,
        isValid,
        save: setCampaignSettings,
        submit,
    };
}
