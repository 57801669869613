import React from 'react';
import PropTypes from 'prop-types';

const CircleNumbers = {
    ONE: 1,
    TWO: 2,
    THREE: 3,
    FOUR: 4,
    FIVE: 5,
};

const numberOne = (
    <path d="M53.6602 39.31L43.3702 46.17V37.14L54.9202 28.95H64.1602V79H53.6602V39.31Z" fill="#C5CBD5" />
);
const numberTwo = (
    <path
        d="M37.8447 73.21L54.5747 58.23C57.2347 55.8033 59.3581 53.4933 60.9447 51.3C62.5314 49.06 63.3247 46.7267 63.3247 44.3C63.3247 42.1067 62.7181 40.4033 61.5047 39.19C60.2914 37.93 58.5414 37.3 56.2547 37.3C53.9214 37.3 52.0314 38.0467 50.5847 39.54C49.1847 40.9867 48.2747 43.4133 47.8547 46.82H38.3347C38.6614 41.1733 40.4114 36.8333 43.5847 33.8C46.8047 30.7667 51.1681 29.25 56.6747 29.25C59.3814 29.25 61.7847 29.6233 63.8847 30.37C65.9847 31.07 67.7581 32.0967 69.2047 33.45C70.6514 34.7567 71.7481 36.3433 72.4947 38.21C73.2881 40.03 73.6847 42.06 73.6847 44.3C73.6847 47.66 72.6581 50.8333 70.6047 53.82C68.5981 56.76 65.7281 59.77 61.9947 62.85L51.2147 72.02H73.7547V80H37.8447V73.21Z"
        fill="#C5CBD5"
    />
);
const numberThree = (
    <path
        d="M55.6166 80.77C52.5366 80.77 49.8066 80.3733 47.4266 79.58C45.0466 78.7867 43.04 77.69 41.4066 76.29C39.82 74.8433 38.5833 73.1167 37.6966 71.11C36.8566 69.0567 36.39 66.7933 36.2966 64.32H45.6766C45.91 66.7933 46.7733 68.8467 48.2666 70.48C49.76 72.1133 52.0933 72.93 55.2666 72.93C58.3 72.93 60.5166 72.2767 61.9166 70.97C63.3633 69.6167 64.0866 67.75 64.0866 65.37C64.0866 60.2833 61.2633 57.74 55.6166 57.74H51.7666V50.6H55.3366C58.09 50.6 60.0966 50.0167 61.3566 48.85C62.6633 47.6367 63.3166 45.91 63.3166 43.67C63.3166 41.71 62.71 40.1233 61.4966 38.91C60.2833 37.6967 58.44 37.09 55.9666 37.09C53.4466 37.09 51.44 37.79 49.9466 39.19C48.4533 40.59 47.5666 42.48 47.2866 44.86H38.0466C38.1866 42.62 38.6766 40.5667 39.5166 38.7C40.4033 36.7867 41.6166 35.13 43.1566 33.73C44.6966 32.33 46.5633 31.2333 48.7566 30.44C50.95 29.6467 53.4233 29.25 56.1766 29.25C58.8366 29.25 61.2166 29.6 63.3166 30.3C65.4633 31 67.26 31.9567 68.7066 33.17C70.1533 34.3833 71.25 35.8067 71.9966 37.44C72.79 39.0733 73.1866 40.8467 73.1866 42.76C73.1866 45.6067 72.44 47.9167 70.9466 49.69C69.4533 51.4633 67.47 52.77 64.9966 53.61C67.9366 54.2633 70.2933 55.6167 72.0666 57.67C73.84 59.6767 74.7266 62.3133 74.7266 65.58C74.7266 67.9133 74.2833 70.0133 73.3966 71.88C72.51 73.7467 71.25 75.3567 69.6166 76.71C67.9833 78.0167 65.9766 79.02 63.5966 79.72C61.2166 80.42 58.5566 80.77 55.6166 80.77Z"
        fill="#C5CBD5"
    />
);

const getCircleNumber = (circleNumber) => {
    switch (circleNumber) {
        case CircleNumbers.ONE:
            return numberOne;
        case CircleNumbers.TWO:
            return numberTwo;
        case CircleNumbers.THREE:
            return numberThree;
        default:
            return numberOne;
    }
};

const CircleWithNumbersOutlineSvg = ({ width = 110, height = 110, circleNumber }) => (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M54.75 109.5C24.5593 109.5 0 84.9407 0 54.75C0 24.5593 24.5593 0 54.75 0C84.9407 0 109.5 24.5593 109.5 54.75C109.5 84.9407 84.9407 109.5 54.75 109.5ZM54.75 7.82143C28.8871 7.82143 7.82143 28.8871 7.82143 54.75C7.82143 80.6129 28.8871 101.679 54.75 101.679C80.6129 101.679 101.679 80.6129 101.679 54.75C101.679 28.8871 80.6129 7.82143 54.75 7.82143Z"
            fill="#C5CBD5"
        />
        {getCircleNumber(circleNumber)}
    </svg>
);

CircleWithNumbersOutlineSvg.propTypes = {
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    circleNumber: PropTypes.number,
};

export default CircleWithNumbersOutlineSvg;
