import { createSelector } from 'reselect';
import { initialState } from './reducer';

/**
 * Direct selector to the homePage state domain
 */

const selectSummaryDashboardPageDomain = (state) => state.summaryDashboardPage || initialState;

/**
 * Other specific selectors
 */
const selectDateFilter = createSelector(selectSummaryDashboardPageDomain, (substate) => substate.dateFilter);

const selectLvlTwoSortOrder = createSelector(selectSummaryDashboardPageDomain, (substate) => substate.sortOrderLvlTwo);

const selectPageNumber = createSelector(selectSummaryDashboardPageDomain, (substate) => substate.pageNumber || 0);

const selectCurrentFilterName = createSelector(
    selectSummaryDashboardPageDomain,
    (substate) => substate.summaryDashboardFilters?.currentFilterName,
);

const selectCurrentFilterStatus = createSelector(
    selectSummaryDashboardPageDomain,
    (substate) => substate.summaryDashboardFilters?.loadStatus,
);

const selectCurrentFilter = createSelector(
    selectSummaryDashboardPageDomain,
    (substate) => substate?.summaryDashboardFilters?.filters,
);

const makeSelectSummaryDashboardPage = () => createSelector(selectSummaryDashboardPageDomain, (substate) => substate);

const makeSelectSummaryFilters = () =>
    createSelector(selectSummaryDashboardPageDomain, (substate) => substate.summaryDashboardFilters);

export default makeSelectSummaryDashboardPage;
export {
    selectSummaryDashboardPageDomain,
    makeSelectSummaryDashboardPage,
    makeSelectSummaryFilters,
    selectCurrentFilterName,
    selectCurrentFilterStatus,
    selectCurrentFilter,
    selectPageNumber,
    selectDateFilter,
    selectLvlTwoSortOrder,
};
