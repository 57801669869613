import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import { HelpOutline } from '@material-ui/icons';
import { Redirect, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useAuthedUser } from 'shared/hooks/useAuthedUser';
import FlyoutModal from 'components/FlyoutModal';
import { ButtonCard } from './ButtonCard';
import PageNotFound from '../../PageNotFound';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';
import BillingInfoFlyout from '../BillingSettings/BillingInfo/BillingInfoFlyout';
import { getSessionIdRequest } from '../actions';
import { GET_SESSION_ID_REQUEST } from '../constants';

// STYLES
const useStyles = makeStyles((theme) => ({
    headline: {
        fontFamily: 'HumanBBYDigital, sans-serif',
        paddingLeft: '20%',
    },
    subtitle: {
        fontFamily: 'HumanBBYDigital, sans-serif',
        paddingLeft: '20%',
    },
    infoCard: {
        marginBlock: '1em',
        paddingLeft: '20%',
        paddingRight: '20%',
        padding: theme.spacing(1),
        boxShadow: '0',
        minHeight: '150px',
    },
    infoCardText: {
        display: 'inline',
    },
    buttonCard: {
        flexGrow: 1,
        gridAutoRows: 'auto',
        gridGap: '1em',
        gridTemplateColumns: 'repeat(3, 1fr)',
        width: '100%',
        paddingLeft: '20%',
        paddingRight: '20%',
    },
    helpHover: {
        display: 'inline-block',
        color: theme.palette.humanBlue,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(1),
        minHeight: '150px',
        boxShadow: '0',
    },
}));

const FlyoutStatus = Object.freeze({
    BILLING: 'billing',
    CARD: 'card',
    NONE: null,
});

const BillingSandboxPage = ({ billingCycle, billingThreshold, invoiceThreshold, firstName }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const showBilling = useSelector((state) => state.featureFlags.showBilling);
    const authedUser = useAuthedUser();

    const onCloseFlyoutModal = () => {
        setOpenFlyout(false);
        setFlyoutStatus(FlyoutStatus.NONE);
    };

    const [flyoutStatus, setFlyoutStatus] = useState(FlyoutStatus.NONE);
    const [openFlyout, setOpenFlyout] = useState(false);
    const [isBillingConfirmed, setBillingConfirmed] = useState(false);

    // COPY
    const sponsoredButtonText = 'Create Campaign';
    const preApprovedTitle = 'Pre-Approved Billing Cycle ';
    const preApprovedSubtitle = `As a MyAds Billing Admin, you'll recieve invoices in two ways:\n1. When your spending hits a certain amount, this is called the invoice threshold.\n2. Every 14 days, is the current standand billing cycle.`;
    const billingThresholdHeader = () => `Spend Limit: $${billingThreshold}`;
    const billingThresholdSubtitle = () => `${invoiceThreshold}% Invoice Threshold\n${billingCycle} Days Billing Cycle`;
    const subtitleText =
        'Before you create a campaign, we need to complete a few steps to ensure your account is ready to go.';
    const title = `Welcome ${firstName}! Let's get started`;
    const sponsoredTitle = (sponsoredProductQuantity) => `Sponsored Product (${sponsoredProductQuantity})`;
    const sponsoredSubtitle = `Create a sponsored product campaign starting with a standard targeting.`;
    const billingInfoButtonText = `Billing Info`;
    const billingInfoButtonTitle = `Confirm Invoicing Details`;
    const billingInfoButtonSubtitle = `Please verify the account information on your invoice and ensure the correct email address is provided to receive your invoice. You can update this information later on your billing managment page.`;
    const addCreditCardButtonText = `Credit Card`;
    const addCreditCardButtonTitle = `Add Credit Card`;
    const addCreditCardButtonSubtitle = `Add Credit Card for recurring payments and to avoid any interruptions in Ad spend.`;

    const handleCampaignRedirect = () => {
        history.push('/create/sponsored/campaign-settings');
    };

    if (!showBilling) {
        return (
            <>
                <PageNotFound />
                <Redirect to="/" />
            </>
        );
    }

    return (
        <>
            <Typography variant="h5" className={classes.headline}>
                {title}
            </Typography>
            <Typography variant="body2" className={classes.subtitle}>
                {subtitleText}
            </Typography>

            <Grid container className={classes.infoCard} spacing={4} alignItems="center">
                <Grid item xs={8}>
                    <Card className={classes.card}>
                        <Grid container direction="column" alignItems="center" spacing={2}>
                            <Grid item>
                                <Typography className={classes.infoCardText} align="left" variant="h5">
                                    {preApprovedTitle}
                                </Typography>
                                <HelpOutline className={classes.helpHover} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                                    {preApprovedSubtitle}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card className={classes.card}>
                        <Typography variant="h6">{billingThresholdHeader()}</Typography>
                        <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                            {billingThresholdSubtitle()}
                        </Typography>
                    </Card>
                </Grid>
            </Grid>
            <Grid className={classes.buttonCard} container direction="column" spacing={2}>
                <Grid item>
                    <ButtonCard
                        buttonText={sponsoredButtonText}
                        loading={false}
                        title={sponsoredTitle(0) /* TODO: wire up sponsoredProductQuantity */}
                        subtitle={sponsoredSubtitle}
                        onClick={() => {
                            handleCampaignRedirect();
                        }}
                        circleNumber={1}
                    />
                </Grid>
                <Grid item>
                    <ButtonCard
                        buttonText={billingInfoButtonText}
                        loading={false}
                        title={billingInfoButtonTitle}
                        subtitle={billingInfoButtonSubtitle}
                        onClick={() => {
                            setFlyoutStatus(FlyoutStatus.BILLING);
                            setOpenFlyout(true);
                        }}
                        circleNumber={2}
                        completed={isBillingConfirmed}
                    />
                </Grid>
                <Grid item>
                    <ButtonCard
                        buttonText={addCreditCardButtonText}
                        loading={false}
                        title={addCreditCardButtonTitle}
                        subtitle={addCreditCardButtonSubtitle}
                        onClick={() => {
                            setOpenFlyout(true);
                            setFlyoutStatus(FlyoutStatus.CARD);
                            dispatch(
                                getSessionIdRequest({
                                    type: GET_SESSION_ID_REQUEST,
                                    orgAccountId: authedUser?.orgAccounts[0],
                                }),
                            );
                        }}
                        circleNumber={3}
                    />
                </Grid>
            </Grid>
            {flyoutStatus === 'billing' ? (
                <BillingInfoFlyout
                    title="Billing Information"
                    open={openFlyout}
                    onClose={onCloseFlyoutModal}
                    setBillingConfirmed={setBillingConfirmed}
                />
            ) : null}
            {flyoutStatus === 'card' ? (
                <ErrorBoundary>
                    <FlyoutModal title="Add Credit Card" open={openFlyout} onClose={onCloseFlyoutModal}></FlyoutModal>
                </ErrorBoundary>
            ) : null}
        </>
    );
};

BillingSandboxPage.propTypes = {
    firstName: PropTypes.string,
    billingCycle: PropTypes.number,
    billingThreshold: PropTypes.number,
    invoiceThreshold: PropTypes.number,
};
BillingSandboxPage.defaultProps = {
    firstName: 'Geoffery', // TODO: remove this when we have a user wired-up
    billingCycle: 14,
    billingThreshold: 1000,
    invoiceThreshold: 20,
};

export default BillingSandboxPage;
