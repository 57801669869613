import React from 'react';
import { Grid, InputLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Field } from 'react-final-form';
import { NumberTextField } from '../../shared/CampaignManagementNumberTextField';

const useStyles = makeStyles((theme) => ({
    inputLabel: {
        '&.MuiFormLabel-root': {
            height: 6,
            padding: '10px 0px',
            color: theme.palette.techBlack,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            fontSize: 15,
            whiteSpace: 'nowrap',
        },
    },
    gridSection: {
        maxWidth: '21rem',
    },
    subtitle: {
        marginTop: '6px',
    },
    suggestedBid: {
        color: 'green',
        fontWeight: 'bold',
    },
}));

const BaseBid = () => {
    const classes = useStyles();

    return (
        <Grid item className={classes.gridSection}>
            <InputLabel className={classes.inputLabel}>Base Bid</InputLabel>
            <Typography variant="body2" className={classes.subtitle}>
                (Min.: $0.30)
            </Typography>
            <Field name="baseBid" component="input">
                {({ input }) => (
                    <NumberTextField
                        name={input?.name}
                        value={String(input?.value)}
                        onChange={input.onChange}
                        disabled={false}
                        isBaseBid
                    />
                )}
            </Field>
        </Grid>
    );
};

export default BaseBid;
